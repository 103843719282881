export const addZero = (num: number): string => {
  if (num > 999) {
    return num.toString();
  }
  return ("000" + num).slice(-3);
};

export const addTwoZero = (num: number): string => {
  return ("000" + num).slice(-2);
};

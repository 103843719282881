import { useAuth } from "@/hooks/useAuth";
import { Button, Form, Input, message, Modal } from "antd";
import { useEffect } from "react";

export const LoginModal = () => {
  const { login, errorMessage } = useAuth();
  const onFinish = (values: any) => {
    login(values);
  };

  useEffect(() => {
    if (errorMessage) {
      message.error(errorMessage);
    }
  }, [errorMessage]);

  return (
    <Modal title="Login" visible={true} width={400} footer={false}>
      <Form
        name="login"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          label="Username"
          name="phone"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

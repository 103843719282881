/* eslint-disable @typescript-eslint/ban-ts-comment */
import ExcelJS, { Borders } from "exceljs";
import { addZero } from "./addZero";

export const downloadProducts = ({ users, party }: any) => {
  const workbook = new ExcelJS.Workbook();

  exportOverhead(users, party, workbook);
  // @ts-ignore
  return workbook.xlsx.writeBuffer({ base64: true }).then((buffer) => {
    // @ts-ignore
    const base64 = buffer.toString("base64");
    const link = document.createElement("a");
    link.setAttribute("type", "hidden");
    link.download = `${addZero(
      party?.number
    )}-party-products-${new Date().toJSON()}.xlsx`;
    link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64}`;
    document.body.appendChild(link);
    link.click();
    link.remove();
  });
};

const exportOverhead = (users: any, party: any, workbook: any) => {
  const ws = workbook.addWorksheet("WorkSheet-1", {
    pageSetup: { paperSize: 9, orientation: "landscape" },
    views: [{ showGridLines: true }],
  });

  const borderThinStyle: Partial<Borders> = {
    top: { style: "thin" },
    right: { style: "thin" },
    bottom: { style: "thin" },
    left: { style: "thin" },
  };

  const styleLeft = {
    alignment: { vertical: "middle", horizontal: "left" },
    font: { size: 9 },
    border: borderThinStyle,
  };

  const styleRight = {
    alignment: { vertical: "middle", horizontal: "right" },
    font: { size: 9 },
    border: borderThinStyle,
  };

  ws.getRow(1).values = [
    "Track number",
    "Name",
    "Party",
    "Quantity",
    "Weight",
    "Price",
  ];

  ws.columns = [
    {
      key: "track_number",
      // width: 4,
      // @ts-ignore
      styleLeft,
    },
    {
      key: "name",
      // width: 4,
      // @ts-ignore
      styleRight,
    },
    {
      key: "party",
      // width: 15,
      // @ts-ignore
      styleLeft,
    },
    {
      key: "count",
      // width: 10,
      // @ts-ignore
      styleRight,
    },
    {
      key: "weight",
      // width: 30,
      // @ts-ignore
      styleLeft,
    },
    {
      key: "price",
      // width: 12,
      // @ts-ignore
      styleRight,
    },
  ];

  const rows = users?.map((item: any) => ({
    ...item,
  }));
  rows.push({});

  for (let i = 0; i < users.length; i++) {
    const user = users[i];

    ws.addRow({
      track_number: "",
      name: `${party?.created_by?.partyName}${addZero(
        party.number || 0
      )}${addZero(i + 1)}`,
      party: "",
      count: "",
      weight: "",
      price: "",
    });

    user.products?.forEach(function (item: any, index: number) {
      ws.addRow({
        track_number: item?.track_number,
        name: item?.name,
        party: `${party?.created_by?.partyName}${addZero(
          party.number || 0
        )}${addZero(i + 1)}`,
        count: item?.count,
        weight: item?.weight,
        price: item?.price,
      });
    });
  }
};

/* eslint-disable @typescript-eslint/ban-ts-comment */
import dayjs from "dayjs";
import ExcelJS from "exceljs";
import { addTwoZero, addZero } from "./addZero";
import { getUserData } from "./getUser";

//  ****************************************
// INVOICE ****************************************
//  ****************************************

export const downloadBulkInvoices = (data) => {
  const workbook = new ExcelJS.Workbook();
  console.log(data);
  for (let index = 0; index < data?.users.length; index++) {
    const user = data?.users[index];

    user?.id && exportOverhead(user, data?.party, workbook);
  }

  // @ts-ignore
  return workbook.xlsx.writeBuffer({ base64: true }).then((buffer) => {
    // @ts-ignore
    const base64 = buffer.toString("base64");
    const link = document.createElement("a");
    link.setAttribute("type", "hidden");
    link.download = `${addZero(
      data?.party?.number
    )}-party-invoices-${new Date().toJSON()}.xlsx`;
    link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64}`;
    document.body.appendChild(link);
    link.click();
    link.remove();
  });
};

const exportOverhead = (user, party, workbook) => {
  const ws = workbook.addWorksheet(
    user?.phone_extra + " WorkSheet" + "-" + addZero(user?.index),
    {
      pageSetup: { paperSize: 9, orientation: "landscape" },
      views: [{ showGridLines: true }],
    }
  );

  const headerDefFontStyle = {
    bold: true,
    name: "Times New Roman",
    size: 9,
  };

  const INVOICEFONT = {
    bold: true,
    name: "Times New Roman",
    size: 12,
  };

  const borderThinStyle = {
    top: { style: "thin" },
    right: { style: "thin" },
    bottom: { style: "thin" },
    left: { style: "thin" },
  };

  const aligmentStyle = {
    wrapText: true,
    vertical: "middle",
    horizontal: "center",
  };

  ws.getColumn("A").width = 8.14;
  ws.getColumn("B").width = 6;
  ws.getColumn("C").width = 37;
  ws.getColumn("D").width = 6;
  ws.getColumn("E").width = 20;
  ws.getColumn("F").width = 12;
  ws.getColumn("G").width = 12;

  ws.getCell("B1").value = `${party?.created_by?.partyName}${addZero(
    party.number || 0
  )}${addZero(user?.index)}`;

  ws.getCell("B1").font = INVOICEFONT;
  ws.getCell("B1").border = borderThinStyle;
  ws.getCell("B1").alignment = aligmentStyle;
  ws.mergeCells("B1:C3");

  ws.getCell("D1").value = "INVOICE DOC";
  ws.getCell("D1").font = headerDefFontStyle;
  ws.getCell("D1").border = borderThinStyle;
  ws.getCell("D1").alignment = aligmentStyle;
  ws.mergeCells("D1:E3");

  ws.getCell("F1").value = `C${user?.cell?.cellNumber} / ${user.phone_extra}`;
  ws.getCell("F1").font = INVOICEFONT;
  ws.getCell("F1").alignment = aligmentStyle;
  ws.getCell("F1").border = borderThinStyle;
  ws.mergeCells("F1:G3");

  ws.getCell("B4").value = "Из:\nDan:";
  ws.getCell("B4").font = headerDefFontStyle;
  ws.getCell("B4").border = borderThinStyle;
  ws.getCell("B4").alignment = aligmentStyle;
  ws.mergeCells("B4:B11");

  ws.getCell("C4").value = "taobao.com и pingduoduo.com";
  ws.getCell("C4").font = headerDefFontStyle;
  ws.getCell("C4").border = borderThinStyle;
  ws.getCell("C4").alignment = aligmentStyle;

  ws.getCell("C5").value =
    "Компания: GUANGZHOU YIYANG GLOBAL SUPPLY CHAIN CO., LTD\n Манзили: ROOM 406 BLOCK F QIANGSHENG BUILDING,NO,12 JIANGXIA NORTH-MIDDLE ROAD,HUANGSHI STREET,BAIYUN DISTRICT GUANGZHOU CITY, CHINA";
  ws.getCell("C5").font = headerDefFontStyle;
  ws.getCell("C5").border = borderThinStyle;
  ws.getCell("C5").alignment = {
    ...aligmentStyle,
    horizontal: "center",
    vertical: "middle",
  };
  ws.mergeCells("C5:C11");

  ws.getCell("D4").value = "В:\nGa:";
  ws.getCell("D4").font = headerDefFontStyle;
  ws.getCell("D4").border = borderThinStyle;
  ws.getCell("D4").alignment = {
    ...aligmentStyle,
    vertical: "middle",
    horizontal: "center",
  };
  ws.mergeCells("D4:D11");

  // GET USER -----------------------------------
  const userInfo = getUserData(user, "fullName");
  const userAddress = getUserData(user, "passport_address");

  ws.getCell("E4").value = userInfo || "-";
  ws.getCell("E4").font = headerDefFontStyle;
  ws.getCell("E4").border = borderThinStyle;
  ws.getCell("E4").alignment = { ...aligmentStyle, vertical: "top" };
  ws.mergeCells("E4:G4");

  ws.getCell("E5").value = userAddress || "-";
  ws.getCell("E5").font = headerDefFontStyle;
  ws.getCell("E5").border = borderThinStyle;
  ws.getCell("E5").alignment = { ...aligmentStyle, horizontal: "center" };
  ws.mergeCells("E5:G11");

  // create a sheet with red tab colour

  const styleLeft = {
    alignment: { vertical: "middle", horizontal: "left" },
    font: { size: 9 },
    border: borderThinStyle,
  };

  const styleRight = {
    alignment: { vertical: "middle", horizontal: "right" },
    font: { size: 9 },
    border: borderThinStyle,
  };

  ws.getRow(12).values = [
    "",
    "№",
    "Tasnif",
    "Trek kod",
    "",
    "Soni",
    "nx (usd)",
  ];

  ws.columns = [
    {
      key: "empty",
      // width: 4,
      // @ts-ignore
      styleLeft,
    },
    {
      key: "index",
      // width: 4,
      // @ts-ignore
      styleRight,
    },
    {
      key: "name",
      // width: 15,
      // @ts-ignore
      styleLeft,
    },
    {
      key: "track_number",
      // width: 30,
      // @ts-ignore
      styleLeft,
    },
    {
      key: "empty",
      // width: 4,
      // @ts-ignore
      styleLeft,
    },
    {
      key: "count",
      // width: 10,
      // @ts-ignore
      styleRight,
    },
    // @ts-ignore
    // {
    //   key: "product_weight",
    //   // width: 12,
    //   // @ts-ignore
    //   styleRight,
    // },
    {
      key: "price",
      // width: 12,
      // @ts-ignore
      styleRight,
    },
    // {
    //   key: "country",
    //   // width: 12,
    //   // @ts-ignore
    //   styleRight,
    // },
  ];

  const rows = user?.products?.map((item) => ({
    ...item,
  }));
  rows?.push({});

  let totalPrice = 0;
  let totalWeight = 0;
  let totalCount = 0;
  rows?.forEach(function (item, index) {
    totalPrice += item?.price * 1000 || 0;
    totalCount += item?.count || 0;
    totalWeight += item?.weight * 1000 || 0;
    if (index !== rows.length - 1)
      ws.addRow({
        empty: "",
        index: index + 1,
        name: item?.name_ru || item?.name,
        track_number: item?.track_number,
        _empty: "",
        count: item?.count,
        // product_weight: item?.weight,
        price: item?.price,
        // country: "Китай",
      });
    // else {
    //   ws.addRow({
    //     empty: "",
    //     index: "-",
    //     name: "Итого",
    //     count: "",
    //     track_number: "",
    //     product_weight: totalWeight,
    //     price: totalPrice,
    //     country: "",
    //   });
    // }
  });

  const trackNumberColumn = ws.getColumn("track_number");
  trackNumberColumn.eachCell((cell, rowNum) => {
    const rowNumber = rowNum + 11;

    if (rowNumber > 1 && rowNum - 1 <= user.products?.length) {
      // Merge cells for rows starting from the second row (excluding header row)

      ws.mergeCells(`D${rowNumber}:E${rowNumber}`);
    }
  });

  const header = ws.getRow(10);
  header.font = { bold: true, name: "Times New Roman", size: 9 };

  ws.getColumn("A").width = 8.14;
  ws.getColumn("B").width = 6;
  ws.getColumn("C").width = 37;
  ws.getColumn("D").width = 6;
  ws.getColumn("E").width = 20;
  ws.getColumn("F").width = 12;
  ws.getColumn("G").width = 12;

  // const tableRow = +ws.rowCount + 1;
  // const tableFooter = ws.getRow(tableRow);
  // ws.getCell(`E${tableRow}`).value = `Жами: ${totalPrice}`;
  // tableFooter.font = {
  //   bold: true,
  //   name: "Times New Roman",
  // };
  // //implement styles
  for (let i = 1; i <= header.cellCount; i++) {
    // header.getCell(i).fill = {
    //   type: "pattern",
    //   pattern: "solid",
    //   fgColor: { argb: "f6f6f6" },
    // };

    // header.getCell(i).alignment = {
    //   wrapText: true,
    // };

    for (let r = 10; r <= ws.rowCount; r++) {
      if (i === 1) continue;
      ws.getCell(r, i).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      ws.getCell(r, i).alignment = aligmentStyle;
    }
  }

  const lastRow = +ws.rowCount + 1;

  ws.getCell(`B${lastRow}`).value = `${party?.created_by?.partyName}${addZero(
    party.number || 0
  )}${addZero(user?.index)} Китай ${dayjs().format("DD.MM.YYYY")}`;
  ws.getCell(`B${lastRow}`).font = headerDefFontStyle;
  ws.getCell(`B${lastRow}`).border = borderThinStyle;
  ws.getCell(`B${lastRow}`).alignment = {
    ...aligmentStyle,
    vertical: "bottom",
  };
  ws.mergeCells(`B${lastRow}:C${lastRow}`);

  ws.getCell(`D${lastRow}`).value = `Og'irligi ${totalWeight / 1000} kg`;
  ws.getCell(`D${lastRow}`).font = headerDefFontStyle;
  ws.getCell(`D${lastRow}`).border = borderThinStyle;
  ws.getCell(`D${lastRow}`).alignment = {
    ...aligmentStyle,
    vertical: "bottom",
  };
  ws.mergeCells(`D${lastRow}:E${lastRow}`);

  ws.getCell(`F${lastRow}`).value = `${totalCount}`;
  ws.getCell(`F${lastRow}`).font = headerDefFontStyle;
  ws.getCell(`F${lastRow}`).border = borderThinStyle;
  ws.getCell(`F${lastRow}`).alignment = {
    ...aligmentStyle,
    vertical: "bottom",
  };

  ws.getCell(`G${lastRow}`).value = `${totalPrice / 1000}`;
  ws.getCell(`G${lastRow}`).font = headerDefFontStyle;
  ws.getCell(`G${lastRow}`).border = borderThinStyle;
  ws.getCell(`G${lastRow}`).alignment = {
    ...aligmentStyle,
    vertical: "bottom",
  };
};

import MainContainer from "@/containers/MainContainer";
import ScanningWrapper from "@/containers/Scanning/ScanningWrapper";
import InvoiceWrapper from "@/containers/Invoice/InvoiceWrapper";
import ProductsWrapper from "@/containers/Products/ProductsWrapper";
import InvoiceByUsers from "@/containers/Invoice/InvoiceByUsers";
import ProhibitedGoods from "@/containers/ProhibitedGoods/ProhibitedGoods";
import AutoScanningWrapper from "@/containers/AutoScanning/AutoScanningWrapper";

export type RouteType = {
  path: string;
  element: () => JSX.Element;
};
export const routeList: RouteType[] = [
  {
    path: "/",
    element: MainContainer,
  },
  {
    path: "/scan",
    element: ScanningWrapper,
  },
  {
    path: "/scan/:id",
    element: ScanningWrapper,
  },
  {
    path: "/auto-scan",
    element: AutoScanningWrapper,
  },
  {
    path: "/invoice/users",
    element: InvoiceByUsers,
  },
  {
    path: "/invoice",
    element: InvoiceWrapper,
  },
  {
    path: "/products",
    element: ProductsWrapper,
  },
  {
    path: "/prohibited-goods",
    element: ProhibitedGoods,
  },
];

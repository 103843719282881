import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { ADD_KEY, SEARCH_KEY } from "@/common/types";
import services from "@/services";
import { useProducts } from "@/store";
import { ScanHeader } from "./components/ProhibitedScanHeader";
import { MainActions } from "./components/ProhibitedSearchTrack";
import { TrackForm } from "./components/ProhibitedTrackForm";
import { TrackTable } from "./components/ProhibitedTrackTable";

const ProhibitedGoods = () => {
  const setProducts = useProducts(
    ({ setProhibitedProducts }) => setProhibitedProducts
  );
  const setIsLoading = useProducts(({ setIsLoading }) => setIsLoading);

  const searchRef = useRef<HTMLInputElement>();
  const inputRef = useRef<null | HTMLInputElement>(null);

  const { data, isSuccess, isLoading } = useQuery(
    ["getProhibitedProduct"],
    () => services.products.getProhibitedProducts()
  );

  useEffect(() => {
    document.addEventListener("keydown", handleOnKeyDown);

    return () => {
      document.removeEventListener("keydown", handleOnKeyDown);
    };
  }, []);

  const handleOnKeyDown = (e: KeyboardEvent) => {
    if (e.key === SEARCH_KEY.KEY && e.keyCode === SEARCH_KEY.CODE) {
      e.preventDefault();
      searchRef?.current && searchRef.current.focus();
    }
    if (e.key === ADD_KEY.KEY && e.keyCode === ADD_KEY.CODE) {
      e.preventDefault();
      inputRef?.current && inputRef.current.focus();
    }
  };

  useEffect(() => {
    if (isSuccess && data) {
      setProducts(data);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  return (
    <div>
      <ScanHeader />
      <MainActions searchRef={searchRef} />
      <TrackForm inputRef={inputRef} />
      <TrackTable />
    </div>
  );
};

export default ProhibitedGoods;

// import { getManifestExcelSheets } from "@/common/utils/sheetExport";
import services from "@/services";
import { Button, Divider, PageHeader, Space, Spin, Table } from "antd";
import { Fragment, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import countBy from "lodash/countBy";
import { fromPairs, sortBy, toPairs } from "lodash";
import dayjs from "dayjs";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { json2xml, js2xml, xml2js } from "xml-js";
import download from "downloadjs";
import {
  // downloadInvoice,
  downloadManifest,
} from "@/common/utils/sheetExportByUser";

const InvoiceWrapper = () => {
  const navigate = useNavigate();

  const { data, isSuccess, isLoading } = useQuery(["party"], () =>
    services.products.getAllParties()
  );

  const { mutate, isLoading: loadingUsers } = useMutation(
    (id: number) => services.products.getAllUsersByBox(id),
    {
      onSuccess: (data) => {
        // downloadInvoice(data);
      },
    }
  );

  const { mutate: mutateByParty, isLoading: loadingUsersByManifest } =
    useMutation((id: number) => services.products.getAllUsersByPary(id), {
      onSuccess: (data) => {
        console.log(data);
        downloadManifest(data);
      },
    });

  const createSnk = (party: any) => {
    const jsonData = {
      main_data: {
        Declaration: {
          decl_type: "1",
          ident_num: "123",
          ident_data: "2022-04-01",
          sending_country: "USA",
          receiving_country: "Canada",
          total_cost: 500,
        },
      },
    };
    const __xml = `<main_data>
    <Declaration>
      <decl_type>1</decl_type>
      <ident_num>CUA244001</ident_num>
      <ident_data>2022-05-29</ident_data>
      <sending_country>156</sending_country>
      <receiving_country>860</receiving_country>
      <total_cost>119.9</total_cost>
      <brutto>10.65</brutto>
      <company_inn>11111111</company_inn>
      <type_firma>1</type_firma>
      <nakl_num>123456</nakl_num>
      <kkdg></kkdg>
      <pnfl>61903046090022</pnfl>
      <last_name>SHODIYEVA</last_name>
      <first_name>OZODA</first_name>
      <father_name></father_name>
      <citizen>860</citizen>
      <pass_ser>AC</pass_ser>
      <pass_num>3037641</pass_num>
      <region>718</region>
      <district>7198</district>
      <address>SAMARQAND VIL PASTARG'OM T GO'ZALLIK</address>
      <internet>1</internet>
      <ConsignmentItem>
        <number>1</number>
        <name>черный свитер</name>
        <unit>796</unit>
        <tiftn></tiftn>
        <quantity>1</quantity>
        <netto></netto>
        <currency></currency>
        <cost>7.91</cost>
      </ConsignmentItem>
      <ConsignmentItem>
        <number>2</number>
        <name>блокнот</name>
        <unit>796</unit>
        <tiftn></tiftn>
        <quantity>1</quantity>
        <netto></netto>
        <currency></currency>
        <cost>10.7</cost>
      </ConsignmentItem>
    </Declaration>
    <Declaration>
      <decl_type>1</decl_type>
      <ident_num>CUA244002</ident_num>
      <ident_data>2022-05-29</ident_data>
      <sending_country>156</sending_country>
      <receiving_country>860</receiving_country>
      <total_cost>112.4</total_cost>
      <brutto>7</brutto>
      <company_inn>11111111</company_inn>
      <type_firma>1</type_firma>
      <nakl_num>123456</nakl_num>
      <kkdg></kkdg>
      <pnfl>60403047020015</pnfl>
      <last_name>RUSTAMOVA</last_name>
      <first_name>NAVBAHOR</first_name>
      <father_name></father_name>
      <citizen>860</citizen>
      <pass_ser>AC</pass_ser>
      <pass_num>2958558</pass_num>
      <region>730</region>
      <district>7309</district>
      <address>FARG'ONA VIL DANG'ARA T BOG'ISHAMOL</address>
      <internet>1</internet>
      <ConsignmentItem>
        <number>1</number>
        <name>куртка цвета хаки</name>
        <unit>796</unit>
        <tiftn></tiftn>
        <quantity>1</quantity>
        <netto></netto>
        <currency></currency>
        <cost>9.99</cost>
      </ConsignmentItem>
      <ConsignmentItem>
        <number>2</number>
        <name>наклейка</name>
        <unit>796</unit>
        <tiftn></tiftn>
        <quantity>1</quantity>
        <netto></netto>
        <currency></currency>
        <cost>2.55</cost>
      </ConsignmentItem>
      <ConsignmentItem>
        <number>3</number>
        <name>Женская обувь</name>
        <unit>796</unit>
        <tiftn></tiftn>
        <quantity>1</quantity>
        <netto></netto>
        <currency></currency>
        <cost>7.73</cost>
      </ConsignmentItem>
      <ConsignmentItem>
        <number>4</number>
        <name>клетчатую рубашку</name>
        <unit>796</unit>
        <tiftn></tiftn>
        <quantity>1</quantity>
        <netto></netto>
        <currency></currency>
        <cost>2.13</cost>
      </ConsignmentItem>
      <ConsignmentItem>
        <number>5</number>
        <name>джинсы</name>
        <unit>796</unit>
        <tiftn></tiftn>
        <quantity>1</quantity>
        <netto></netto>
        <currency></currency>
        <cost>8.58</cost>
      </ConsignmentItem>
      <ConsignmentItem>
        <number>6</number>
        <name>синие шорты</name>
        <unit>796</unit>
        <tiftn></tiftn>
        <quantity>1</quantity>
        <netto></netto>
        <currency></currency>
        <cost>9.14</cost>
      </ConsignmentItem>
    </Declaration>
    <Declaration>
      <decl_type>1</decl_type>
      <ident_num>CUA244003</ident_num>
      <ident_data>2022-05-29</ident_data>
      <sending_country>156</sending_country>
      <receiving_country>860</receiving_country>
      <total_cost>83.8</total_cost>
      <brutto>6.55</brutto>
      <company_inn>11111111</company_inn>
      <type_firma>1</type_firma>
      <nakl_num>123456</nakl_num>
      <kkdg></kkdg>
      <pnfl>62208046350039</pnfl>
      <last_name>SALOMOVA</last_name>
      <first_name>SALOMOVA</first_name>
      <father_name></father_name>
      <citizen>860</citizen>
      <pass_ser>AD</pass_ser>
      <pass_num>0846796</pass_num>
      <region>722</region>
      <district>7221</district>
      <address>SURXONDARYO VIL TERMIZ SH TINCHLIK</address>
      <internet>1</internet>
      <ConsignmentItem>
        <number>1</number>
        <name>черный короткий рукав</name>
        <unit>796</unit>
        <tiftn></tiftn>
        <quantity>1</quantity>
        <netto></netto>
        <currency></currency>
        <cost>0.44</cost>
      </ConsignmentItem>
      <ConsignmentItem>
        <number>2</number>
        <name>подходить</name>
        <unit>796</unit>
        <tiftn></tiftn>
        <quantity>1</quantity>
        <netto></netto>
        <currency></currency>
        <cost>3.43</cost>
      </ConsignmentItem>
    </Declaration>
  </main_data>`;

    console.log(xml2js(__xml));

    const options = {
      compact: true,
      ignoreComment: true,
      spaces: 4,
    };

    const xmlData = js2xml(jsonData, options);
    const xmlDeclaration = '<?xml version="1.0" encoding="UTF-8"?>\n';
    const fullXml = xmlDeclaration + xmlData;
    download(fullXml, "data.xml", "application/xml");
  };

  const state: any = {
    bordered: true,
    loading: false,
    size: "small",
    title: undefined,
    scroll: undefined,
    hasData: true,
    tableLayout: undefined,
    top: "none",
    bottom: "bottomRight",
    pagination: false,
  };

  return (
    <div className="container">
      <div>
        <div className="scan-header">
          <PageHeader
            ghost={false}
            title={"Parties"}
            onBack={() => navigate("/")}
          />
        </div>
        <Spin spinning={loadingUsers}>
          <div style={{ margin: "1rem" }}>
            {data?.map((party) => (
              <div key={party?.id}>
                <Divider />
                <Space size={"middle"} align="start" direction="horizontal">
                  <h2>Party number: {("000" + party?.number).slice(-3)}</h2>
                  {/* <Button
                    type="primary"
                    style={{ marginBottom: "5px" }}
                    icon={<CloudDownloadOutlined />}
                    onClick={() => {
                      getInvoiveExcelSheets(party);
                    }}
                  >
                    Download Invoice for all
                  </Button> */}
                  <Button
                    type="dashed"
                    style={{ marginBottom: "5px" }}
                    icon={<CloudDownloadOutlined />}
                    onClick={() => {
                      // getManifestExcelSheets(party);
                    }}
                  >
                    Download Manifest
                  </Button>
                  <Button
                    type="link"
                    style={{ marginBottom: "5px" }}
                    icon={<CloudDownloadOutlined />}
                    onClick={() => {
                      createSnk(party);
                    }}
                  >
                    Download SNK
                  </Button>
                </Space>
                <Table
                  rowKey={"id"}
                  dataSource={party?.boxes || []}
                  size="small"
                  loading={isLoading}
                  {...state}
                >
                  <Table.Column title="Box" dataIndex="name" key="name" />
                  <Table.Column
                    title="Weight"
                    dataIndex="weight"
                    key="weight"
                  />
                  <Table.Column
                    title="Created date"
                    dataIndex="created_at"
                    key="created_at"
                    render={(text) => dayjs(text).format("DD-MM-YYYY")}
                  />
                  <Table.Column
                    title="Action"
                    key="action"
                    render={(record) => (
                      <Button
                        onClick={() => {
                          // getInvoiveExcelSheets({ ...party, boxes: [record] });
                          mutate(record?.id);
                        }}
                        type="default"
                        icon={<CloudDownloadOutlined />}
                      >
                        Download invoice
                      </Button>
                    )}
                  />
                </Table>
              </div>
            ))}
          </div>
        </Spin>
      </div>
    </div>
  );
};
export default InvoiceWrapper;

import { IUser } from "@/common/types";
import create from "zustand";
import { persist } from "zustand/middleware";

type UserInfoMethods = {
  setUser: (data: Partial<IUser> | null) => void;
};

type UserInfoState = {
  user: Partial<IUser> | null;
} & UserInfoMethods;

const useUserInfo = create<UserInfoState>(
  persist(
    (set, get) => ({
      user: null,
      setUser: (data: any) => {
        if (!data) {
          set({ user: null });
        } else
          set({
            user: data,
          });
      },
    }),
    {
      name: "user-storage",
    }
  )
);

export default useUserInfo;

import { IUser } from "@/common/types";
import { AxiosRequestConfig } from "axios";
import BaseApi from "../base.service";

class UserApi extends BaseApi {
  constructor() {
    super();
  }

  logout() {
    localStorage.removeItem("user");
    // return await this.api.
  }

  async userInfo(config?: AxiosRequestConfig) {
    const res = await this.api.get<IUser>("/auth/userinfo", config);

    return res;
  }

  async getUsersByProducts() {
    const res = await this.api.get("/users/products-china");

    return res.data;
  }
}

export default UserApi;

import { ADD_KEY, SEARCH_KEY } from "@/common/types";
import services from "@/services";
import { useCells, useParties, useProducts } from "@/store";
import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { ScanHeader } from "./components/ScanHeader";
import { MainActions } from "./components/ScanMainActions";
import { TrackForm } from "./components/TrackForm";
import { TrackTable } from "./components/TrackTable";
import { CellsModal } from "./components/CellsModal";
import { CellGroupModal } from "./components/CellGroupModal";

const ScanningWrapper = () => {
  const { id: cellId } = useParams();
  const setProducts = useProducts(({ setProducts }) => setProducts);
  const setAllProducts = useProducts(({ setAllProducts }) => setAllProducts);
  const setCell = useCells(({ setCell }) => setCell);
  const setIsLoadingCell = useCells(({ setIsLoadingCell }) => setIsLoadingCell);
  const party = useParties(({ party }) => party);

  const searchRef = useRef<HTMLInputElement>();
  const inputRef = useRef<null | HTMLInputElement>(null);

  const { data, isSuccess, isLoading } = useQuery(
    ["getCell", cellId, party],
    () => {
      if (party?.id) {
        if (!cellId) {
          return services.cell.getCell(party?.id);
        } else {
          return services.cell.getById(cellId);
        }
      }
    },
    {
      refetchOnMount: false,
    }
  );

  useEffect(() => {
    document.addEventListener("keydown", handleOnKeyDown);

    return () => {
      document.removeEventListener("keydown", handleOnKeyDown);
    };
  }, []);

  const handleOnKeyDown = (e: KeyboardEvent) => {
    if (e.key === SEARCH_KEY.KEY && e.keyCode === SEARCH_KEY.CODE) {
      e.preventDefault();
      searchRef?.current && searchRef.current.focus();
    }
    if (e.key === ADD_KEY.KEY && e.keyCode === ADD_KEY.CODE) {
      e.preventDefault();
      inputRef?.current && inputRef.current.focus();
    }
  };

  useEffect(() => {
    if (isSuccess && data) {
      setCell(data);
      setProducts(data?.products);
      setAllProducts(data?.products);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    setIsLoadingCell(isLoading);
  }, [isLoading]);

  return (
    <div>
      <ScanHeader />
      <MainActions searchRef={searchRef} />
      <TrackForm inputRef={inputRef} />
      <TrackTable />
      <CellsModal />
      <CellGroupModal />
    </div>
  );
};

export default ScanningWrapper;

import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import authHeader from "./auth-header";

export default class BaseApi {
  protected api: AxiosInstance;

  constructor() {
    const hostname = window.location.hostname;
    const parts = hostname.split(".");
    let subdomain = "";

    // If the domain has more than two parts (e.g., subdomain.domain.com)
    if (parts.length > 2) {
      subdomain = parts[0];
    }

    const api =
      subdomain?.indexOf("test") > -1
        ? process.env.REACT_APP_URL_TEST
        : process.env.REACT_APP_URL;
    const url = api;

    this.api = axios.create({
      baseURL: url,
    });
    this.api.interceptors.request.use(function (config: AxiosRequestConfig) {
      config.headers = { ...config.headers, ...authHeader() };
      return config;
    });
  }
}

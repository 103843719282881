import { BoxStatus, IBox, IProduct } from "@/common/types";
import services from "@/services";
import { useBoxes, useCells, useParties, useProducts } from "@/store";
import { Badge, Button, message, Modal, Space, Spin, Tag } from "antd";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";

export const CellsModal = () => {
  const queryClient = useQueryClient();
  const party = useParties(({ party }) => party);
  const cell = useCells(({ cell }) => cell);
  const visible = useCells(({ visibleModal }) => visibleModal);
  const isToCell = useCells(({ isToCell }) => isToCell);
  const setVisible = useCells(({ setIsToCell }) => setIsToCell);
  const selectedRowKeys = useProducts(({ selectedRowKeys }) => selectedRowKeys);

  const [cellState, setCellState] = useState([]);

  const setSelectedRowKeys = useProducts(
    ({ setSelectedRowKeys }) => setSelectedRowKeys
  );

  const { data: cells, isLoading } = useQuery(
    ["getAllCellsQuery", party],
    () => services.cell.getAllCells(party?.id),
    {
      enabled: visible && !!party?.id,
    }
  );

  const { mutate, isLoading: isLoadingBox } = useMutation(
    (id: number) => services.products.productsToBox(selectedRowKeys, id),
    {
      onSuccess: () => {
        message.success("Succesfully added");
        setSelectedRowKeys([]);
        setVisible(false);
      },
      onError: (err) => {
        message.error("Something went error");
      },
      onSettled: () => {
        queryClient.invalidateQueries("getCell");
      },
    }
  );

  useEffect(() => {
    if (cells && cells.length > 0) {
      const _cells = cells
        .filter((item: any) => item.products?.length > 0)
        .map((item: any) => {
          let totalWeight = 0;
          item.products?.forEach((product: IProduct) => {
            totalWeight += product.weight ? product.weight * 1000 : 0;
          });
          return {
            ...item,
            weight: totalWeight / 1000 || 0,
          };
        });

      setCellState(_cells);
    }
  }, [cells]);

  const handleToCell = (__cellId: number) => {
    mutate(__cellId);
  };

  return (
    <Modal
      title="Boxes"
      footer={false}
      visible={visible}
      onCancel={() => setVisible(false)}
    >
      <Spin tip="...Loading" spinning={isLoading || isLoadingBox}>
        <div style={{ minHeight: "100px" }}>
          <Space size={24} wrap>
            {cellState?.map((item: any) => (
              <Badge
                key={item.id}
                count={item?.products?.length ?? 0}
                color="green"
                title={item?.products?.length}
                overflowCount={1000}
              >
                {isToCell ? (
                  <Button
                    size="large"
                    type={cell?.id === item.id ? "primary" : "default"}
                    disabled={cell?.id === item.id}
                    onClick={() => handleToCell(item.id)}
                  >
                    Cell {item?.cellNumber}
                    <Tag color="blue" style={{ marginLeft: "5px" }}>
                      {item.weight || 0} kg
                    </Tag>
                  </Button>
                ) : (
                  <Button
                    size="large"
                    type={cell?.id === item.id ? "primary" : "default"}
                    onClick={() => {
                      setVisible(false);
                    }}
                  >
                    <Link to={`/scan/${item.id}`}>
                      Cell{item.cellNumber}
                      <Tag color="blue" style={{ marginLeft: "5px" }}>
                        {item.weight || 0} kg
                      </Tag>
                    </Link>
                  </Button>
                )}
              </Badge>
            ))}
          </Space>
        </div>
      </Spin>
    </Modal>
  );
};

import { IProduct } from "@/common/types";
import services from "@/services";
import { useParties } from "@/store";
import { Button, Col, Form, Input, InputNumber, message, Row } from "antd";
import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";

const rules = [{ required: true }];

export const TrackForm = ({ inputRef }: any) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const party = useParties(({ autoParty }) => autoParty);

  const { mutate, isLoading } = useMutation(
    services.products.addProductToAutoParty,
    {
      onSuccess: (res) => {
        if (res.status === 200) {
          message.success("Succesfully added");
          form.resetFields();
          inputRef?.current.focus();
          return;
        }
      },
      onError: (err: AxiosError) => {
        if (err?.response?.status === 409) {
          message.warn(
            "The user's product has another cell, please choose cell"
          );
        }
        if (err?.response && err?.response?.data?.message) {
          message.error(err?.response?.data?.message, 5);
          return;
        }
        message.error("Something went error");
      },
      onSettled: () => {
        queryClient.invalidateQueries("getAutoChina");
      },
    }
  );

  const handleAdd = async (values: IProduct) => {
    mutate({ ...values, party });
  };

  return (
    <div className="container main-form">
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={handleAdd}
        wrapperCol={{ span: 23 }}
        requiredMark={false}
      >
        <Row align="top">
          <Col span={6}>
            <Form.Item label="Track Number" name="track_number" rules={rules}>
              <Input
                style={{ width: "100%" }}
                placeholder="Track Number"
                ref={inputRef}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item required label="User ID" name="user" rules={rules}>
              <Input style={{ width: "100%" }} placeholder="User ID" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item required label="Name" name="name" rules={rules}>
              <Input placeholder="Short name" />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item required label="Count" name="count" rules={rules}>
              <InputNumber
                style={{ width: "100%" }}
                controls={false}
                placeholder="Count"
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item required label="Weight" name="weight" rules={rules}>
              <InputNumber
                style={{ width: "100%" }}
                controls={false}
                placeholder="Weight"
              />
            </Form.Item>
          </Col>
          <Col span={5} style={{ textAlign: "right" }}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "80%", minWidth: 150, marginTop: "22px" }}
                loading={isLoading}
              >
                Add
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

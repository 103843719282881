import { PageHeader } from "antd";
import { useNavigate } from "react-router-dom";

import "./styles.scss";

export const ProductsHeader = () => {
  const navigate = useNavigate();
  return (
    <div className="scan-header">
      <PageHeader
        ghost={false}
        title={`Products`}
        onBack={() => navigate("/")}
      />
    </div>
  );
};

import { ADD_KEY } from "@/common/types";
import services from "@/services";
import { useProducts } from "@/store";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { ProductsHeader } from "./components/ProductsHeader";
import { MainActions } from "./components/MainAction";
import { ProductsTable } from "./components/ProductsTable";

const ProductsWrapper = () => {
  const setProducts = useProducts(({ setProducts }) => setProducts);
  const setAllProducts = useProducts(({ setAllProducts }) => setAllProducts);
  const setIsLoading = useProducts(({ setIsLoading }) => setIsLoading);

  const searchRef = useRef<HTMLInputElement>();
  const [searchValue, setSearchValue] = useState<string | null>(null);

  const { data, isSuccess, isLoading } = useQuery(
    ["allProducts", searchValue],
    async () => {
      const products = await services.products.getAllProducts({
        take: 30,
        keyword: searchValue || "",
      });
      console.log("products: ", products);
      if (products?.data?.length > 0) return products;

      return services.products.getAllProhibitProducts({
        take: 30,
        keyword: searchValue || "",
      });
    },
    {
      refetchOnMount: false,
    }
  );

  useEffect(() => {
    document.addEventListener("keydown", handleOnKeyDown);

    return () => {
      document.removeEventListener("keydown", handleOnKeyDown);
    };
  }, []);

  const handleOnKeyDown = (e: KeyboardEvent) => {
    if (e.key === ADD_KEY.KEY && e.keyCode === ADD_KEY.CODE) {
      e.preventDefault();
      searchRef?.current && searchRef.current.focus();
    }
  };

  useEffect(() => {
    if (isSuccess && data) {
      setProducts(data?.data);
      setAllProducts(data?.data);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  return (
    <div>
      <ProductsHeader />
      <MainActions
        searchRef={searchRef}
        setSearchValue={(value: string | null) => setSearchValue(value)}
      />
      <ProductsTable isLoadingData={isLoading} />
    </div>
  );
};

export default ProductsWrapper;

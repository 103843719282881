/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { addZero } from "../utils/addZero";
import {
  getProductsTotalCount,
  getProductsTotalPrice,
  getProductsTotalWeight,
  getUserData,
} from "../utils/getUser";
import dayjs from "dayjs";

import "./invoicepdf.scss";

export const InvoicePdf = ({ user, party, ref }) => {
  return (
    <table
      border="0"
      cellPadding="0"
      cellSpacing="0"
      id="sheet0"
      className="sheet0 gridlines"
    >
      <thead>
        <tr>
          <th scope="col" className="col0"></th>
          <th scope="col" className="col1"></th>
          <th scope="col" className="col2"></th>
          <th scope="col" className="col3"></th>
          <th scope="col" className="col4"></th>
          <th scope="col" className="col5"></th>
          <th scope="col" className="col6"></th>
        </tr>
      </thead>
      <tbody>
        <tr className="row0">
          <td className="column0">&nbsp;</td>
          <td className="column1 style1 s style1" colSpan="2" rowSpan="3">
            {party?.created_by?.partyName}
            {addZero(party?.number)}
            {addZero(user?.index)}
          </td>
          <td className="column3 style2 s style2" colSpan="2" rowSpan="3">
            INVOICE DOC
          </td>
          <td className="column5 style1 s style1" colSpan="2" rowSpan="3">
            C{user?.cell?.cellNumber || 0} / {user?.phone_extra}
          </td>
        </tr>
        <tr className="row1">
          <td className="column0">&nbsp;</td>
        </tr>
        <tr className="row2">
          <td className="column0">&nbsp;</td>
        </tr>
        <tr className="row3">
          <td className="column0">&nbsp;</td>
          <td className="column1 style2 s style2" rowSpan="8">
            Из:
            <br />
            Dan:
          </td>
          <td className="column2 style2 s">taobao.com и pingduoduo.com</td>
          <td className="column3 style2 s style2" rowSpan="8">
            В:
            <br />
            Ga:
          </td>
          <td className="column4 style3 s style3" colSpan="3">
            {getUserData(user, "fullName")}
          </td>
        </tr>
        <tr className="row4">
          <td className="column0">&nbsp;</td>
          <td className="column2 style2 s style2 style-mini-size" rowSpan="7">
            Компания: GUANGZHOU YIYANG GLOBAL SUPPLY CHAIN CO., LTD
            <br />
            &nbsp;Манзили: ROOM 406 BLOCK F QIANGSHENG BUILDING,NO,12 JIANGXIA
            NORTH-MIDDLE ROAD,HUANGSHI STREET,BAIYUN DISTRICT GUANGZHOU CITY,
            CHINA
          </td>
          <td className="column4 style2 s style2" colSpan="3" rowSpan="7">
            {getUserData(user, "passport_address")}
          </td>
        </tr>
        <tr className="row5">
          <td className="column0">&nbsp;</td>
        </tr>
        <tr className="row6">
          <td className="column0">&nbsp;</td>
        </tr>
        <tr className="row7">
          <td className="column0">&nbsp;</td>
        </tr>
        <tr className="row8">
          <td className="column0">&nbsp;</td>
        </tr>
        <tr className="row9">
          <td className="column0">&nbsp;</td>
        </tr>
        <tr className="row10">
          <td className="column0">&nbsp;</td>
        </tr>
        <tr className="row11">
          <td className="column0 style0 s"></td>
          <td className="column1 style5 s">№</td>
          <td className="column2 style5 s">Tasnif</td>
          <td className="column3 style5 s style5" colSpan="2">
            Trek kod
          </td>
          <td className="column5 style5 s">Soni</td>
          <td className="column6 style5 s">nx (usd)</td>
        </tr>

        {user?.products?.map((product, index) => (
          <tr key={product?.id} className="row12">
            <td className="column0">&nbsp;</td>
            <td className="column1 style5 n">{index + 1}</td>
            <td className="column2 style5 s">
              {product?.name_ru || product?.name}
            </td>
            <td className="column3 style5 s style5" colSpan="2">
              {product?.track_number}
            </td>
            <td className="column5 style5 n">{product?.count}</td>
            <td className="column6 style5 n">{product?.price}</td>
          </tr>
        ))}
        <tr className="row26">
          <td className="column0">&nbsp;</td>
          <td className="column1 style6 s style6" colSpan="2">
            {party?.created_by?.partyName}
            {addZero(party?.number)}
            {addZero(user?.index)} Китай {dayjs().format("DD.MM.YYYY")}
          </td>
          <td className="column3 style6 s style6" colSpan="2">
            Og&apos;irligi {getProductsTotalWeight(user?.products) / 1000} kg
          </td>
          <td className="column5 style6 s">
            {getProductsTotalCount(user.products)}
          </td>
          <td className="column6 style6 s">
            {getProductsTotalPrice(user?.products) / 1000}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default InvoicePdf;

export enum BoxStatus {
  CHINA = "china",
  BATCH = "batch",
  STORE = "store",
  ARCHIVE = "archive",
}

export interface IUser {
  id: number;
  first_name: string;
  last_name: string;
  phone: string;
  phone_extra: string;
  passport_serial: string;
  passport_photos: null | string[];
  role: "admin" | "partner" | "user";
}

export interface IProduct {
  id: number;
  user?: IUser;
  track_number: string;
  name: string;
  count: number;
  price?: number;
  weight?: number;
  isComplete: boolean;
  userId: number;
  specificUser?: IUser;
}

export interface IBox {
  id: number;
  name: string;
  weight: number;
  isOpen: boolean;
  status: BoxStatus;
  products: IProduct[];
}

export enum SEARCH_KEY {
  KEY = "F1",
  CODE = 112,
}

export enum ADD_KEY {
  KEY = "Escape",
  CODE = 27,
}

export interface IParty {
  id: number;
  number: number;
  readonly boxes: IBox[];
  created_by?: any;
  name?: string;
  isAvto: boolean;
}

export interface IPartyInfo {
  id: number;
  number: number;
  totalProducts: string;
  totalWeight: number;
  totalPrice: number;
  totalUsers: number;
}

/* eslint-disable react/display-name */
import { ChangeEvent, useEffect, useState } from "react";
import { useBoxes, useProducts } from "@/store";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Space } from "antd";
import { useDebounce } from "@/hooks/useBounce";

import "./styles.scss";

export const MainActions = ({ searchRef, setSearchValue }: any) => {
  const [search, setSearch] = useState("");

  const debounceSearch = useDebounce(search, 500);

  useEffect(() => {
    if (debounceSearch?.length > 0) {
      setSearchValue(debounceSearch);
    } else {
      setSearchValue(null);
    }
  }, [debounceSearch]);

  const onChange = ({ target }: any) => {
    setSearch(target.value);
  };

  return (
    <div className="container main-actions">
      <Row align="middle" gutter={8}>
        <Col span={12}>
          <Input
            size="large"
            placeholder="Search by Track Number"
            suffix={<SearchOutlined />}
            className="main-search"
            ref={searchRef}
            onChange={onChange}
            value={search}
            allowClear
          />
        </Col>
      </Row>
    </div>
  );
};

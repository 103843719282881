import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import { Button, Input, InputNumber, message, Space, Table, Modal } from "antd";
import { IProduct } from "@/common/types";
import services from "@/services";
import { useCells, useProducts } from "@/store";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { AxiosError } from "axios";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";

const { confirm } = Modal;

export const TrackTable = () => {
  const navigation = useNavigate();
  const { id: cellId } = useParams();

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(services.products.update, {
    onSuccess: (res) => {
      const { data } = res;
      setEditableRow(null);

      if (data?.isNewCellGroup) {
        message.warning(
          <span>
            This customer`s product{" "}
            <strong>{data?.product?.track_number}</strong> have been divided
            into another invoice. The reason is that the number of products
            exceeds <strong>30</strong> or the weight exceeds{" "}
            <strong>10 kg</strong>
          </span>,
          10
        );
      }
      if (res.status === 200) {
        message.success("Succesfully updated");
        return;
      }
      if (res.status === 201) {
        message.warning(
          <span>
            This user&apos;s product{" "}
            <strong>{data?.product?.track_number}</strong> has in another Cell{" "}
            <strong>CELL {data?.cell?.cellNumber}</strong> and added to this
            cell
          </span>,
          10
        );
      }
      message.success("Succesfully updated");
    },
    onError: (err: AxiosError) => {
      if (err?.response && err?.response?.data?.message) {
        message.error(err?.response?.data?.message);
        return;
      }
      message.error("Something went error");
    },
    onSettled: () => {
      queryClient.invalidateQueries("getCell");
    },
  });

  const { mutate: mutateSeparately, isLoading: isLoadingSeparately } =
    useMutation(services.products.updateSeparately, {
      onSuccess: (res) => {
        const { data } = res;
        setEditableRow(null);

        if (data?.isNewCellGroup) {
          message.warning(
            <span>
              This customer`s product{" "}
              <strong>{data?.product?.track_number}</strong> have been divided
              into another invoice. The reason is that the number of products
              exceeds <strong>30</strong> or the weight exceeds{" "}
              <strong>10 kg</strong>
            </span>,
            10
          );
        }
        if (res.status === 200) {
          message.success("Succesfully updated");
          return;
        }
        if (res.status === 201) {
          message.warning(
            <span>
              This user&apos;s product{" "}
              <strong>{data?.product?.track_number}</strong> has in another Cell{" "}
              <strong>CELL {data?.cell?.cellNumber}</strong> and added to this
              cell
            </span>,
            10
          );
        }
        message.success("Succesfully updated");
      },
      onError: (err: AxiosError) => {
        if (err?.response && err?.response?.data?.message) {
          message.error(err?.response?.data?.message);
          return;
        }
        message.error("Something went error");
      },
      onSettled: () => {
        queryClient.invalidateQueries("getCell");
      },
    });

  const { mutate: mutateDelete, isLoading: isDeleting } = useMutation(
    services.products.deleteProduct,
    {
      onSuccess: (data) => {
        message.success("Succesfully deleted");
      },
      onError: (err: AxiosError) => {
        if (err?.response && err?.response?.data?.message) {
          message.error(err?.response?.data?.message);
          return;
        }
        message.error("Something went error");
      },
      onSettled: () => {
        queryClient.invalidateQueries("getCell");
      },
    }
  );

  const { mutate: mutateClose, isLoading: isClosing } = useMutation(
    services.cell.closeCell,
    {
      onSuccess: (data) => {
        message.success("Succesfully Closed");
        if (cellId) {
          navigation("/scan");
        }
      },
      onError: (err) => {
        message.error("Something went error");
      },
      onSettled: () => {
        setTimeout(() => {
          queryClient.invalidateQueries(["getCell", undefined]);
        }, 1000);
      },
    }
  );

  const products = useProducts(({ products }) => products);
  const setSelectedRowKeys = useProducts(
    ({ setSelectedRowKeys }) => setSelectedRowKeys
  );
  const selectedRowKeys = useProducts(({ selectedRowKeys }) => selectedRowKeys);
  const cell = useCells(({ cell }) => cell);
  const isLoadingCell = useCells(({ isLoadingCell }) => isLoadingCell);
  const isSeparately = useCells(({ isSeparately }) => isSeparately);

  const [editableRow, setEditableRow] = useState<IProduct | null>(null);
  const [userIds, setUserIds] = useState<any>([]);

  useEffect(() => {
    if (products.length > 0) {
      const uniqueUsers = _.uniqBy(products, (product: any) => product.user.id);

      const userIds = _.map(uniqueUsers, (product: any) => ({
        text: product?.user?.phone_extra,
        value: product?.user?.phone_extra,
      }));
      setUserIds(userIds);
    }
  }, [products]);

  const TableFooter = () => (
    <Space align="center" style={{ width: "100%", justifyContent: "flex-end" }}>
      <Button
        type="primary"
        style={{ minWidth: "200px" }}
        onClick={handleCloseCell}
        loading={isClosing}
        disabled={products?.length === 0}
      >
        Close Cell
      </Button>
    </Space>
  );

  const handleCloseCell = () => {
    if (products.length > 0) {
      mutateClose({ id: cell?.id });
    }
  };

  const onSelectChange = (record: IProduct, selected: boolean) => {
    const _keys = selected
      ? [...selectedRowKeys, record]
      : selectedRowKeys.filter((item: any) => item?.id !== record.id);

    setSelectedRowKeys(_keys);
  };

  const onSelectChangeAll = (
    selected: boolean,
    selectedRows: IProduct[],
    changeRows: IProduct[]
  ) => {
    if (selected) {
      const _keys = [...selectedRowKeys, ...changeRows];
      setSelectedRowKeys(_keys);
    } else {
      const _keys = selectedRowKeys.filter(
        (item: any) => !changeRows.find((_item) => _item.id === item?.id)
      );
      setSelectedRowKeys(_keys);
    }
  };

  const state: any = {
    bordered: true,
    loading: false,
    size: "small",
    title: undefined,
    footer: TableFooter,
    rowSelection: {
      selectedRowKeys: selectedRowKeys?.map((item: any) => item.id),
      onSelect: onSelectChange,
      onSelectAll: onSelectChangeAll,
    },
    scroll: undefined,
    hasData: true,
    tableLayout: undefined,
    top: "none",
    bottom: "bottomRight",
    pagination: false,
  };

  const TableAction = (record: IProduct) => {
    if (editableRow?.id === record?.id) {
      return (
        <Space size="middle">
          <Button
            onClick={() => setEditableRow(null)}
            key="Cancel"
            size="small"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              confirm({
                title: "Do you Want to save?",
                icon: <ExclamationCircleOutlined />,
                onOk() {
                  handleSave();
                },
              });
            }}
            key="Save"
            icon={<SaveOutlined />}
            type="primary"
            loading={isLoading || isLoadingSeparately}
            size="small"
          >
            Save
          </Button>
        </Space>
      );
    }
    return (
      <Space size="middle">
        <Button
          onClick={() => {
            confirm({
              title: "Do you Want to delete?",
              icon: <ExclamationCircleOutlined />,
              onOk() {
                mutateDelete(record.id);
              },
            });
          }}
          key="delete"
          danger
          icon={<DeleteOutlined />}
          loading={isDeleting}
          size="small"
        />
        <Button
          onClick={() => {
            setEditableRow(record);
          }}
          key="edit"
          icon={<EditOutlined />}
          size="small"
        >
          Edit
        </Button>
      </Space>
    );
  };

  const onChange = (e: any) => {
    const { value, name } = e?.target || {};
    if (value && name && editableRow?.id) {
      const data = {
        ...editableRow,
        [name]: value,
      };
      setEditableRow(data);
    }
  };

  const onChangeNumber = (value: number) => {
    if (value && editableRow?.id) {
      const data = {
        ...editableRow,
        count: value,
      };
      setEditableRow(data);
    }
  };
  const onChangePrice = (value: number) => {
    if (value && editableRow?.id) {
      const data = {
        ...editableRow,
        price: value,
      };
      setEditableRow(data);
    }
  };

  const onChangeWeight = (value: number) => {
    if (value && editableRow?.id) {
      const data = {
        ...editableRow,
        weight: value,
      };
      setEditableRow(data);
    }
  };

  const onChangeUser = (e: any) => {
    const { value, name } = e?.target || {};
    if (value && editableRow?.id && editableRow?.user) {
      const data = {
        ...editableRow,
        user: {
          ...editableRow?.user,
          phone_extra: String(value),
        },
      };
      setEditableRow(data);
    }
  };

  const onChangeSpecificUser = (e: any) => {
    const { value, name } = e?.target || {};
    if (value && editableRow?.id && editableRow?.user) {
      const data = {
        ...editableRow,
        specificUser: {
          ...editableRow?.user,
          phone_extra: String(value),
        },
      };
      setEditableRow(data);
    }
  };

  const handleSave = () => {
    isSeparately
      ? mutateSeparately({
          ...editableRow,
          user: editableRow?.user?.phone_extra,
          specificUser: editableRow?.specificUser?.phone_extra || undefined,
          cellId: cell?.id,
        })
      : mutate({
          ...editableRow,
          user: editableRow?.user?.phone_extra,
          specificUser: editableRow?.specificUser?.phone_extra || undefined,
          cellId: cell?.id,
        });
  };

  return (
    <div className="container">
      <div>
        <Table
          rowKey={(record) => {
            return record.id;
          }}
          {...state}
          dataSource={products}
          size="small"
          loading={isLoadingCell}
        >
          <Table.Column
            title="Track Number"
            dataIndex="track_number"
            key="track_number"
            render={(text, record: any) => {
              if (record?.id === editableRow?.id) {
                return (
                  <Input
                    name="track_number"
                    value={editableRow?.track_number}
                    onChange={onChange}
                  />
                );
              }
              return text;
            }}
          />
          <Table.Column
            title="User ID"
            dataIndex="user"
            key="user"
            filters={userIds}
            filterSearch={true}
            onFilter={(
              value: string | number | boolean,
              record: any
            ): boolean => record.user.phone_extra.startsWith(value)}
            render={(text, record: any) => {
              if (record?.id === editableRow?.id) {
                return (
                  <Input
                    name="user"
                    value={editableRow?.user?.phone_extra}
                    onChange={onChangeUser}
                  />
                );
              }
              return text.phone_extra;
            }}
            sorter={(a, b) => +a.user.phone_extra - +b.user.phone_extra}
          />
          <Table.Column
            title="Specific ID"
            dataIndex="specificUser"
            key="specificUser"
            width={100}
            render={(text, record: any) => {
              if (record?.id === editableRow?.id) {
                return (
                  <Input
                    name="specificUser"
                    value={editableRow?.specificUser?.phone_extra}
                    onChange={onChangeSpecificUser}
                  />
                );
              }
              return text?.phone_extra;
            }}
          />
          <Table.Column
            title="Name"
            dataIndex="name"
            key="name"
            width={320}
            render={(text, record: any) => {
              if (record?.id === editableRow?.id) {
                return (
                  <Input
                    name="name"
                    value={editableRow?.name}
                    onChange={onChange}
                  />
                );
              }
              return text;
            }}
          />
          <Table.Column
            title="Count"
            dataIndex="count"
            key="count"
            width={60}
            render={(text, record: any) => {
              if (record?.id === editableRow?.id) {
                return (
                  <InputNumber
                    name="count"
                    value={editableRow?.count}
                    onChange={onChangeNumber}
                  />
                );
              }
              return text;
            }}
          />
          <Table.Column
            title="Weight"
            dataIndex="weight"
            key="weight"
            width={60}
            render={(text, record: any) => {
              if (record?.id === editableRow?.id) {
                return (
                  <InputNumber
                    name="weight"
                    value={editableRow?.weight}
                    onChange={onChangeWeight}
                  />
                );
              }
              return text;
            }}
          />
          <Table.Column
            title="Price"
            dataIndex="price"
            key="price"
            width={60}
            render={(text, record: any) => {
              if (record?.id === editableRow?.id) {
                return (
                  <InputNumber
                    name="price"
                    value={editableRow?.price}
                    onChange={onChangePrice}
                  />
                );
              }
              return text;
            }}
          />
          <Table.Column title="Action" key="action" render={TableAction} />
        </Table>
        {/* <Modal
          title={`Do you Want to Close BOX${box?.id}?`}
          visible={openModal}
          okText="Ok"
          cancelText="Cancel"
          onOk={handleCloseBox}
          onCancel={() => {
            setBoxWeight(undefined);
            setOpenModal(false);
          }}
        >
          <div>
            <p>Enter weight</p>
            <InputNumber
              style={{ width: "100%" }}
              value={boxWeight}
              onChange={(value) => setBoxWeight(value)}
            />
          </div>
        </Modal> */}
      </div>
    </div>
  );
};

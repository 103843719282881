import { IBox, IParty } from "@/common/types";
import create from "zustand";
import { devtools } from "zustand/middleware";

type PartyState = {
  parties: IParty[];
  party: IParty | null;
  isLoading: boolean;
  autoParty: IParty | null;

  setParties: (value: IParty[]) => void;
  setParty: (value: IParty | null) => void;
  setPartyLoading: (value: boolean) => void;
  setAutoParty: (value: IParty | null) => void;
};

const useParties = create<PartyState>(
  devtools(
    (set) => {
      return {
        parties: [],
        party: null,
        autoParty: null,
        isLoading: true,

        setParties: (value) => set({ parties: value }),
        setParty: (value) => set({ party: value }),
        setAutoParty: (value) => set({ autoParty: value }),
        setPartyLoading: (value) => set({ isLoading: value }),
      };
    },
    { name: "Party" }
  )
);

export default useParties;

import { useEffect, useState } from "react";

import { Table } from "antd";
import { useProducts } from "@/store";
import _ from "lodash";

export const TrackTable = () => {
  const products = useProducts(({ prohibitedProducts }) => prohibitedProducts);

  const isLoading = useProducts(({ isLoading }) => isLoading);

  const [userIds, setUserIds] = useState<any>([]);

  useEffect(() => {
    if (products.length > 0) {
      const uniqueUsers = _.uniqBy(products, (product: any) => product.user.id);

      const userIds = _.map(uniqueUsers, (product: any) => ({
        text: product?.user?.phone_extra,
        value: product?.user?.phone_extra,
      }));
      setUserIds(userIds);
    }
  }, [products]);

  const state: any = {
    bordered: true,
    loading: false,
    size: "small",
    title: undefined,
    scroll: undefined,
    hasData: true,
    tableLayout: undefined,
    top: "none",
    bottom: "bottomRight",
    pagination: false,
  };

  return (
    <div className="container">
      <div>
        <Table
          rowKey={(record) => {
            return record.id;
          }}
          {...state}
          dataSource={products}
          size="small"
          loading={isLoading}
        >
          <Table.Column
            title="Track Number"
            dataIndex="track_number"
            key="track_number"
          />
          <Table.Column
            title="User ID"
            dataIndex="user"
            key="user"
            filters={userIds}
            filterSearch={true}
            onFilter={(
              value: string | number | boolean,
              record: any
            ): boolean => record.user.phone_extra.startsWith(value)}
            sorter={(a, b) => +a.user.phone_extra - +b.user.phone_extra}
            render={(text) => text.phone_extra}
          />
          <Table.Column title="Name" dataIndex="name" key="name" width={320} />
          <Table.Column
            title="Count"
            dataIndex="count"
            key="count"
            width={60}
          />
          <Table.Column
            title="Weight"
            dataIndex="weight"
            key="weight"
            width={60}
          />
          <Table.Column
            title="Price"
            dataIndex="price"
            key="price"
            width={60}
          />
        </Table>
      </div>
    </div>
  );
};

import Auth from "./auth";
import CellApi from "./cell/cell";
import ProductAPI from "./products/products";
import UserApi from "./user";

class Services {
  auth: Auth;
  user: UserApi;
  products: ProductAPI;
  cell: CellApi;

  constructor() {
    this.auth = new Auth();
    this.user = new UserApi();
    this.products = new ProductAPI();
    this.cell = new CellApi();
  }
}

export default new Services();

import { Route, Routes } from "react-router-dom";
import { routeList, RouteType } from "./routes";
import { useParties } from "@/store";
import { Spin } from "antd";

const RouteWrapper = () => {
  const isLoading = useParties(({ isLoading }) => isLoading);
  return (
    <Spin spinning={isLoading}>
      <Routes>
        {routeList?.map(({ path, element: Component }: RouteType, index) => (
          <Route key={index} path={path} element={<Component />} />
        ))}
      </Routes>
    </Spin>
  );
};

export default RouteWrapper;

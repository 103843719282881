import { AxiosRequestHeaders } from "axios";

export default function authHeader(): AxiosRequestHeaders {
  const user = JSON.parse(localStorage.getItem("token") || "null");

  if (user && user.access_token) {
    return { Authorization: "Bearer" + " " + user.access_token };
  } else {
    // auth error handler
    return {};
  }
}

import { countBy, sortBy, toPairs } from "lodash";

type UserDataType = "all" | "fullName" | "passport_address";

export const getUser = (products: any, type: UserDataType = "all") => {
  const usersCountObj = countBy(products, (item) => item?.user?.id);

  const idCountObj = sortBy(
    toPairs(usersCountObj)?.map((item) => ({
      id: item[0],
      count: item[1],
    })),
    "count"
  ).reverse()[0];

  const user =
    products?.find((item: any) => item?.user?.id === Number(idCountObj?.id))
      ?.user || {};

  return user ? getUserData(user, type) : "";
};

export const getUserData = (user: any, type: UserDataType = "all") => {
  const data = user?.recipient ? user?.recipient : user;
  switch (type) {
    case "fullName":
      return `${data?.last_name?.toUpperCase()} ${data?.first_name?.toUpperCase()} `;
    case "passport_address":
      return `${data?.passport_serial?.toUpperCase()} ${data?.address?.toUpperCase()} \ntel:${processPhoneNumber(
        data?.phone
      )}`;
    default:
      return `${data?.last_name?.toUpperCase()} ${data?.first_name?.toUpperCase()} ${data?.passport_serial?.toUpperCase()} ${data?.address.toUpperCase()}`;
  }
};

export const getProductsTotalPrice = (products: any) => {
  const totalPrice = products?.reduce(
    (acc: number, item: any) => item?.price * 1000 + acc,
    0
  );

  return totalPrice || 0;
};

export const getProductsTotalWeight = (products: any) => {
  const totalWeight = products?.reduce(
    (acc: number, item: any) => item?.weight * 1000 + acc,
    0
  );

  return totalWeight || 0;
};

export const getProductsTotalCount = (products: any) => {
  const totalCount = products?.reduce(
    (acc: number, item: any) => item?.count + acc,
    0
  );

  return totalCount || 0;
};

export const getProductsName = (products: any) => {
  const names = products.map((item: any) => item?.name_ru).join("\n");
  return names;
};

function processPhoneNumber(phoneNumber: string) {
  // Check if the phone number starts with '+'
  if (phoneNumber.startsWith("+")) {
    return phoneNumber;
  }

  // Check if the length of the phone number is exactly 9
  if (phoneNumber.length === 9) {
    return "+998" + phoneNumber;
  }

  // If the phone number is more than 9 digits and doesn't start with '+'
  if (phoneNumber.length > 9) {
    return "+" + phoneNumber;
  }

  // Return the original phone number if none of the above conditions are met
  return phoneNumber;
}

import { BoxStatus, IBox, IParty, IPartyInfo } from "@/common/types";
import BaseApi from "../base.service";

export default class ProductAPI extends BaseApi {
  constructor() {
    super();
  }
  async getBox(): Promise<IBox> {
    const result = await this.api.get("/boxes/open");
    return result.data;
  }
  getAllBoxes = async (status: BoxStatus): Promise<IBox[]> => {
    const result = await this.api.get("/boxes/status/" + status);
    return result.data;
  };
  async getById(id: string): Promise<IBox> {
    const result = await this.api.get("/boxes/" + id);
    return result.data;
  }

  closeBox = async ({ id, weight }: any) => {
    const result = await this.api.patch(`/boxes/${id}`, { weight });
    return result.data;
  };

  productsToBox = async (ids: number[], id: number) => {
    const result = await this.api.patch(`/boxes/to/${id}`, ids);
    return result.data;
  };

  changeStatus = async (ids: number[], status: BoxStatus) => {
    const result = await this.api.post(`/boxes/status/${status}`, ids);
    return result.data;
  };

  addProduct = async (data: any) => {
    const result = await this.api.post(
      `/products/to-cell/${data?.cellId}`,
      data
    );
    return result;
  };

  addProductSeparately = async (data: any) => {
    const result = await this.api.post(
      `/products/separately/to-cell/${data?.cellId}`,
      data
    );
    return result;
  };

  addProhibitedProduct = async (data: any) => {
    const result = await this.api.post(`/products/prohibited-product`, data);
    return result;
  };

  update = async (data: any) => {
    const result = await this.api.put(`/products/${data?.id}`, data);
    return result;
  };

  updateSeparately = async (data: any) => {
    const result = await this.api.put(`/products/separately/${data?.id}`, data);
    return result;
  };

  deleteProduct = async (id: any) => {
    const result = await this.api.delete(`/products/${id}`);
    return result.data;
  };

  getAllParties = async (): Promise<IParty[]> => {
    const result = await this.api.get("/party");
    return result.data;
  };

  getCurrentPary = async (): Promise<IParty> => {
    const timestamp = Date.now();
    const result = await this.api.get(`/party/current?timestamp=${timestamp}`);
    return result.data;
  };

  getCurrentParyInfo = async (id: number): Promise<IPartyInfo> => {
    const result = await this.api.get(`/party/current/${id}`);
    return result.data;
  };

  sendPartyToUzebekistan = async (id: number) => {
    const result = await this.api.patch(`/party/${id}`);
    return result.data;
  };

  sendAutoPartyToUzebekistan = async (id: number) => {
    const result = await this.api.patch(`/party/auto/${id}`);
    return result.data;
  };

  getAllProducts = async (params: any) => {
    const result = await this.api.get("/products", { params });
    return result.data;
  };

  getAllProhibitProducts = async (params: any) => {
    const result = await this.api.get("/products/prohibited", { params });
    return result.data;
  };

  getAllUsersByBox = async (id: any) => {
    const result = await this.api.get(`/users/box/${id}`);
    return result.data;
  };

  getAllUsersByPary = async (id: any) => {
    const result = await this.api.get(`/users/party/${id}`);
    return result.data;
  };

  addProductsToCellGroup = async (data: any) => {
    const result = await this.api.post("/products/divide", data);
    return result.data;
  };

  updateProductsCellGroup = async (data: any) => {
    const result = await this.api.put("/products/to-group", data);
    return result.data;
  };

  getProhibitedProducts = async () => {
    const timestamp = Date.now();
    const result = await this.api.get(
      `/products/prohibited-product?timestamp=${timestamp}`
    );
    return result.data;
  };

  getAutoParty = async () => {
    const timestamp = Date.now();
    const result = await this.api.get(
      `/party/auto-china?timestamp=${timestamp}`
    );

    return result.data;
  };

  addProductToAutoParty = async (data: any) => {
    const result = await this.api.post(`/products/to-auto`, data);
    return result;
  };

  changePartyIsAvto = async ({ id, data }: any) => {
    const result = await this.api.put(`/party/isAvto/${id}`, data);
    return result;
  };
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { IProduct } from "@/common/types";
import create from "zustand";
import { devtools } from "zustand/middleware";

type ProductState = {
  products: IProduct[];
  prohibitedProducts: Partial<IProduct[]>;
  allProducts: IProduct[];
  selectedRowKeys: any;
  isLoading: boolean;

  autoProducts: IProduct[];
  allAutoProducts: IProduct[];

  setProducts: (values: IProduct[]) => void;
  setAllProducts: (values: IProduct[]) => void;
  setSelectedRowKeys: (values: any) => void;
  setIsLoading: (value: boolean) => void;
  setProhibitedProducts: (values: Partial<IProduct[]>) => void;
  setAutoProducts: (values: IProduct[]) => void;
  setAllAutoProducts: (values: IProduct[]) => void;
};

const useProducts = create<ProductState>(
  devtools(
    (set) => ({
      products: [],
      allProducts: [],
      selectedRowKeys: [],
      isLoading: false,
      prohibitedProducts: [],
      autoProducts: [],
      allAutoProducts: [],

      setProducts: (values: IProduct[]) => set({ products: values }),
      setAllProducts: (values: IProduct[]) => set({ allProducts: values }),
      setSelectedRowKeys: (values: any) => set({ selectedRowKeys: values }),
      setIsLoading: (value: boolean) => set({ isLoading: value }),
      setProhibitedProducts: (values: Partial<IProduct[]>) =>
        set({ prohibitedProducts: values }),
      setAutoProducts: (values: IProduct[]) => set({ autoProducts: values }),
      setAllAutoProducts: (values: IProduct[]) =>
        set({ allAutoProducts: values }),
    }),
    { name: "Products" }
  )
);

export default useProducts;

import services from "@/services";
import { useParties, useUser } from "@/store";
import { message } from "antd";
import { useEffect, useState } from "react";

export const useAuth = (checkAuth = false) => {
  const setUser = useUser((state) => state.setUser);
  const setParty = useParties((state) => state.setParty);
  const setPartyLoading = useParties((state) => state.setPartyLoading);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);

  useEffect(() => {
    if (checkAuth) {
      const token = JSON.parse(localStorage.getItem("token") || "null");
      if (token?.access_token) {
        getUser();
      }
    }
  }, [checkAuth]);

  const login = async (data: any) => {
    setPartyLoading(true);
    const res: any = await services.auth.login(data);
    if (res?.status === 200) {
      localStorage.setItem("token", JSON.stringify(res.data));
      getUser();
    } else if (res?.response.status === 401) {
      setErrorMessage(res?.response.data?.message as string);
      setTimeout(() => {
        setErrorMessage(null);
      }, 2000);
      setPartyLoading(false);
    }
  };

  const getUser = async () => {
    try {
      const res = await services.user.userInfo();
      if (res.status === 200) {
        message.success("Logged in");
        setUser(res.data);
        getParty();
      } else {
        setUser(null);
        setPartyLoading(false);
        await services.auth.logout();
      }
    } catch (err) {
      setUser(null);
      await services.auth.logout();
      setPartyLoading(false);
    }
  };

  const getParty = async () => {
    setPartyLoading(true);
    try {
      const res = await services.products.getCurrentPary();
      setParty(res);
    } catch (err) {
      setParty(null);
    } finally {
      setPartyLoading(false);
    }
  };

  const logOut = async () => {
    setUser(null);
    await services.auth.logout();
  };

  return {
    login,
    errorMessage,
    logOut,
    getParty,
  };
};

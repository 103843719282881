import { addZero } from "@/common/utils/addZero";
import { useAuth } from "@/hooks/useAuth";
import services from "@/services";
import { useParties } from "@/store";
import { Descriptions, message, Modal, Spin } from "antd";
import { useMutation, useQuery } from "react-query";

interface ToPartyModalProps {
  visible: boolean;
  setVisible: (v: boolean) => void;
}

export const ToPartyModal = ({ visible, setVisible }: ToPartyModalProps) => {
  const party = useParties(({ party }) => party);
  const { getParty } = useAuth();

  const { data, isLoading } = useQuery(
    ["partyInfo", party],
    () => services.products.getCurrentParyInfo(party?.id || 0),
    {
      enabled: visible && !!party,
    }
  );

  const { mutate, isLoading: isLoadingParty } = useMutation(
    (id: number) => services.products.sendPartyToUzebekistan(id),
    {
      onSuccess: () => {
        message.success("Succesfully sended to uzbekistan");
        getParty();
        setVisible(false);
      },
      onError: (err) => {
        message.error("Something went error");
      },
    }
  );

  const handleChangeStatus = () => {
    if (data?.id) {
      mutate(data?.id);
    } else {
      message.warn("Please refresh the page");
    }
  };

  return (
    <Modal
      title="Current Party"
      visible={visible}
      onCancel={() => setVisible(false)}
      onOk={handleChangeStatus}
      okButtonProps={{
        loading: isLoadingParty,
        disabled: !data?.id,
      }}
      okText="Send to Uzbekistan"
    >
      <Spin tip="...Loading" spinning={isLoading || isLoadingParty}>
        <div style={{ minHeight: "100px" }}>
          <Descriptions
            title={`Party: ${party?.created_by?.name}${addZero(
              data?.number || 0
            )}`}
          >
            <Descriptions.Item>
              <br />
              Total users: {data?.totalUsers}
              <br />
              Total products: {data?.totalProducts}
              <br />
              Total price: {Math.floor((data?.totalPrice || 0) * 1000) / 1000}
              <br />
              Total weight: {Math.floor((data?.totalWeight || 0) * 1000) / 1000}
            </Descriptions.Item>
          </Descriptions>
        </div>
      </Spin>
    </Modal>
  );
};

/* eslint-disable react/display-name */
import { useEffect, useState } from "react";
import { useProducts } from "@/store";
import { SearchOutlined } from "@ant-design/icons";
import { Col, Input, Row } from "antd";
import { useDebounce } from "@/hooks/useBounce";
import _ from "lodash";

import "./styles.scss";

export const MainActions = ({ searchRef }: any) => {
  const allProducts = useProducts(({ allProducts }) => allProducts);
  const setProducts = useProducts(({ setProducts }) => setProducts);

  const [search, setSearch] = useState("");

  const debounceSearch = useDebounce(search, 500);

  useEffect(() => {
    if (debounceSearch?.length > 0) {
      const _data = allProducts.filter(
        (item) => item.track_number.indexOf(debounceSearch) > -1
      );
      setProducts(_data);
    } else {
      setProducts(allProducts);
    }
  }, [debounceSearch, allProducts]);

  const onChange = ({ target }: any) => {
    setSearch(target.value);
  };

  return (
    <div className="container main-actions">
      <Row align="middle" gutter={8}>
        <Col span={12}>
          <Input
            size="large"
            placeholder="Search by Track Number"
            suffix={<SearchOutlined />}
            className="main-search"
            ref={searchRef}
            onChange={onChange}
            value={search}
            allowClear
          />
        </Col>
      </Row>
    </div>
  );
};

import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import { Button, message, Space, Table, Modal } from "antd";
import { IProduct } from "@/common/types";
import services from "@/services";
import { useParties, useProducts } from "@/store";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { AxiosError } from "axios";
import _ from "lodash";

const { confirm } = Modal;

export const TrackTable = () => {
  const queryClient = useQueryClient();

  const isLoading = useParties(({ isLoading }) => isLoading);

  const { mutate: mutateDelete, isLoading: isDeleting } = useMutation(
    services.products.deleteProduct,
    {
      onSuccess: () => {
        message.success("Succesfully deleted");
      },
      onError: (err: AxiosError) => {
        if (err?.response && err?.response?.data?.message) {
          message.error(err?.response?.data?.message);
          return;
        }
        message.error("Something went error");
      },
      onSettled: () => {
        queryClient.invalidateQueries("getAutoChina");
      },
    }
  );

  const products = useProducts(({ autoProducts }) => autoProducts);
  const [userIds, setUserIds] = useState<any>([]);

  useEffect(() => {
    if (products?.length > 0) {
      const uniqueUsers = _.uniqBy(
        products,
        (product: any) => product?.user?.id
      );

      const userIds = _.map(uniqueUsers, (product: any) => ({
        text: product?.user?.phone_extra,
        value: product?.user?.phone_extra,
      }));
      setUserIds(userIds);
    }
  }, [products]);

  const state: any = {
    bordered: true,
    loading: false,
    size: "small",
    title: undefined,
    scroll: undefined,
    hasData: true,
    tableLayout: undefined,
    top: "none",
    bottom: "bottomRight",
    pagination: false,
  };

  const TableAction = (record: IProduct) => {
    return (
      <Space size="middle">
        <Button
          onClick={() => {
            confirm({
              title: "Do you Want to delete?",
              icon: <ExclamationCircleOutlined />,
              onOk() {
                mutateDelete(record.id);
              },
            });
          }}
          key="delete"
          danger
          icon={<DeleteOutlined />}
          loading={isDeleting}
          size="small"
        />
      </Space>
    );
  };

  return (
    <div className="container">
      <div>
        <Table
          rowKey={(record) => {
            return record.id;
          }}
          {...state}
          dataSource={products}
          size="small"
          loading={isLoading}
        >
          <Table.Column
            title="Track Number"
            dataIndex="track_number"
            key="track_number"
          />
          <Table.Column
            title="User ID"
            dataIndex="user"
            key="user"
            filters={userIds}
            filterSearch={true}
            onFilter={(
              value: string | number | boolean,
              record: any
            ): boolean => record.user?.phone_extra?.startsWith(value)}
            render={(text) => {
              return text?.phone_extra;
            }}
            sorter={(a, b) => +a.user?.phone_extra - +b.user?.phone_extra}
          />
          <Table.Column title="Name" dataIndex="name" key="name" width={320} />
          <Table.Column
            title="Count"
            dataIndex="count"
            key="count"
            width={60}
          />
          <Table.Column
            title="Weight"
            dataIndex="weight"
            key="weight"
            width={60}
          />
          <Table.Column
            title="Price"
            dataIndex="price"
            key="price"
            width={60}
          />
          <Table.Column title="Action" key="action" render={TableAction} />
        </Table>
      </div>
    </div>
  );
};

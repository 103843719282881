import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import RouteWrapper from "@/routes/RouteWrapper";
import { LoginModal } from "./containers/Login";
import { useUser } from "./store";
import { useAuth } from "./hooks/useAuth";

import "antd/dist/antd.min.css";
import { useEffect } from "react";
import services from "./services";

const queryClient = new QueryClient();

function App() {
  useAuth(true);
  const user = useUser((state) => state.user);

  useEffect(() => {
    if (user?.id) {
      process.env.NODE_ENV === "production" &&
        setInterval(() => {
          getUserInfo();
        }, 15000);
    }
  }, [user]);

  const getUserInfo = async () => {
    await services.user.userInfo();
  };

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Router>
          <RouteWrapper />
        </Router>
        {!user && <LoginModal />}
      </QueryClientProvider>
    </div>
  );
}

export default App;

/* eslint-disable react/display-name */
import { useEffect, useState } from "react";
import { useProducts } from "@/store";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, message } from "antd";
import { useDebounce } from "@/hooks/useBounce";
import { ToPartyAutoModalModal } from "@/containers/ToPartyModal/ToUzbekistanAutoCargo";

import "./styles.scss";

export const MainActions = ({ searchRef }: any) => {
  const allProducts = useProducts(({ allAutoProducts }) => allAutoProducts);
  const setProducts = useProducts(({ setAutoProducts }) => setAutoProducts);
  const [openModal, setOpenModal] = useState(false);

  const [search, setSearch] = useState("");

  const debounceSearch = useDebounce(search, 500);

  useEffect(() => {
    if (debounceSearch?.length > 0) {
      const _data = allProducts.filter(
        (item) => item.track_number.indexOf(debounceSearch) > -1
      );
      setProducts(_data);
    } else {
      setProducts(allProducts);
    }
  }, [debounceSearch, allProducts]);

  const onChange = ({ target }: any) => {
    setSearch(target.value);
  };

  return (
    <div className="container main-actions">
      <Row align="middle" gutter={8}>
        <Col span={12}>
          <Input
            size="large"
            placeholder="Search by Track Number"
            suffix={<SearchOutlined />}
            className="main-search"
            ref={searchRef}
            onChange={onChange}
            value={search}
            allowClear
          />
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <Button
            size="large"
            type="primary"
            className="main-action-btn"
            onClick={() => setOpenModal(true)}
            disabled={allProducts?.length === 0}
          >
            To Uzbekistan
          </Button>
        </Col>
      </Row>

      <ToPartyAutoModalModal
        visible={openModal}
        setVisible={(value) => setOpenModal(value)}
      />
    </div>
  );
};

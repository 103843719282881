/* eslint-disable @typescript-eslint/ban-ts-comment */
import ExcelJS, { Alignment, Borders, Font } from "exceljs";
import { addZero } from "./addZero";
import {
  getProductsName,
  getProductsTotalPrice,
  getProductsTotalWeight,
  getUserData,
} from "./getUser";

//  ****************************************
// MANIFESY ****************************************
//  ****************************************

export const downloadManifest = (data: any) => {
  const workbook = new ExcelJS.Workbook();

  exportManifestOverhead(data, workbook);
  // @ts-ignore
  return workbook.xlsx.writeBuffer({ base64: true }).then((buffer) => {
    // @ts-ignore
    const base64 = buffer.toString("base64");
    const link = document.createElement("a");
    link.setAttribute("type", "hidden");
    link.download = `${
      data?.party?.number
    }-party-manifest-${new Date().toJSON()}.xlsx`;
    link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64}`;
    document.body.appendChild(link);
    link.click();
    link.remove();
  });
};

const exportManifestOverhead = ({ party, users }: any, workbook: any) => {
  const ws = workbook.addWorksheet("WorkSheet-1", {
    pageSetup: { paperSize: 9, orientation: "landscape" },
    views: [{ showGridLines: true }],
  });

  const headerDefFontStyle: Partial<Font> = {
    bold: true,
    name: "Times New Roman",
    size: 10,
  };
  const borderThinStyle: Partial<Borders> = {
    top: { style: "thin" },
    right: { style: "thin" },
    bottom: { style: "thin" },
    left: { style: "thin" },
  };

  const aligmentStyle: Partial<Alignment> = {
    wrapText: true,
    vertical: "middle",
    horizontal: "center",
  };

  ws.getColumn("A").width = 5;
  ws.getColumn("B").width = 7.5;
  ws.getColumn("C").width = 7.5;
  ws.getColumn("D").width = 8;
  ws.getColumn("E").width = 16;
  ws.getColumn("F").width = 20;
  ws.getColumn("G").width = 7.5;
  ws.getColumn("H").width = 7.5;
  ws.getColumn("I").width = 7.5;

  // ws.getCell("B2").border = borderThinStyle;

  ws.getCell("A1").value =
    "Жўнатувчи ташкилот номи: GUANGZHOU YIYANG GLOBAL SUPPLY CHAIN CO,,LTD";
  ws.getCell("A1").font = headerDefFontStyle;
  ws.getCell("A1").border = borderThinStyle;
  ws.mergeCells("A1:D1");
  ws.getCell("A1").alignment = aligmentStyle;

  ws.getCell("E1").value =
    "Қабул қилувчи ташкилот номи:YUMA BEST SOFT Limited Liability Company";
  ws.getCell("E1").font = headerDefFontStyle;
  ws.getCell("E1").border = borderThinStyle;
  ws.mergeCells("E1:I1");
  ws.getCell("E1").alignment = aligmentStyle;

  ws.getRow(1).height = 40;

  ws.getCell("A2").value =
    "Манзили: ROOM 406 BLOCK F QIANGSHENG BUILDING,NO,12 JIANGXIA NORTH-MIDDLE ROAD,HUANGSHI STREET,BAIYUN DISTRICT GUANGZHOU CITY, CHINA Телефон рақами: 13560272583";
  ws.getCell("A2").font = { ...headerDefFontStyle, bold: false };
  ws.getCell("A2").border = borderThinStyle;
  ws.getCell("A2").alignment = aligmentStyle;
  ws.mergeCells("A2:D2");

  ws.getCell("E2").value =
    "Манзили: 36 Room, 8 Apartment, Yalangach Street Mirzo Ulugbek district, Tashkent city, Republic of Uzbekistan Телефон рақами: +998977200821 STIR-307269925";
  ws.getCell("E2").font = { ...headerDefFontStyle, bold: false };
  ws.getCell("E2").border = borderThinStyle;
  ws.getCell("E2").alignment = aligmentStyle;
  ws.mergeCells("E2:I2");

  ws.getRow(2).height = 50;

  ws.getCell("A3").value = `CARGO MANIFEST ГРУЗОВОЙ МАНИФЕСТ CU${
    party.number || 159
  }`;
  ws.getCell("A3").font = headerDefFontStyle;
  ws.getCell("A3").border = borderThinStyle;
  ws.getCell("A3").alignment = aligmentStyle;
  ws.mergeCells("A3:I3");

  ws.getCell("A4").value =
    "Name of documents in cargo manifest/ Перечень сведений, указываемых в манифест";
  ws.getCell("A4").font = { ...headerDefFontStyle, bold: false };
  ws.getCell("A4").border = borderThinStyle;
  ws.getCell("A4").alignment = aligmentStyle;
  ws.mergeCells("A4:I4");

  ws.getCell("A5").value = "Waybill number/ Номер транспортной накладной";
  ws.getCell("A5").font = { ...headerDefFontStyle, bold: false };
  ws.getCell("A5").border = borderThinStyle;
  ws.getCell("A5").alignment = aligmentStyle;
  ws.mergeCells("A5:I5");

  // create a sheet with red tab colour

  const styleLeft = {
    alignment: { vertical: "middle", horizontal: "left" },
    font: { size: 9 },
    border: borderThinStyle,
  };

  const styleRight = {
    alignment: { vertical: "middle", horizontal: "right" },
    font: { size: 9 },
    border: borderThinStyle,
  };

  ws.getRow(6).values = [
    "№",
    "Invoice numbers / 1. Тижорат хужжати раками",
    "sender’s name / 2. Халқаро куриерлик жўнатмаси жўнатувчиси",
    "Receiver’s name / 3. Халқаро куриерлик жўнатмаси қабул қилувчиси",
    "Passport number/ ва унинг манзили",
    "Short name / 4. Жўнатманинг қисқача номи",
    "Gross weight / 5. Жўнатманинг брутто вазни",
    "Total price / 6. Фактура ыиймати",
    "Currency / 7. Валюта номи",
  ];

  ws.columns = [
    {
      key: "index",
      // width: 4,
      // @ts-ignore
      styleRight,
    },
    {
      key: "party_name",
      // width: 4,
      // @ts-ignore
      styleLeft,
    },
    {
      key: "sender_name",
      // width: 15,
      // @ts-ignore
      alignment: { vertical: "middle", horizontal: "center" },
      font: { size: 9, bold: true },
      border: borderThinStyle,
    },
    {
      key: "receiver_name",
      // width: 10,
      // @ts-ignore
      styleRight,
    },
    {
      key: "passpor_address",
      // width: 30,
      // @ts-ignore
      styleLeft,
    },
    // @ts-ignore
    {
      key: "short_name",
      width: 100,
      alignment: { vertical: "middle", horizontal: "center" },
      font: { size: 9, bold: true },
      border: borderThinStyle,
    },
    {
      key: "weight",
      // width: 12,
      // @ts-ignore
      styleRight,
    },
    {
      key: "price",
      // width: 12,
      // @ts-ignore
      styleRight,
    },
    {
      key: "currency",
      // width: 12,
      // @ts-ignore
      styleRight,
    },
  ];

  const rows = [...users];
  rows.push({});

  let totalWeight = 0;
  let totalPrice = 0;
  rows?.forEach(function (item: any, index: number) {
    totalWeight += getProductsTotalWeight(item?.products) || 0;
    totalPrice += getProductsTotalPrice(item?.products) || 0;
    if (index !== rows.length - 1)
      ws.addRow({
        index: index + 1,
        party_name: `${party?.created_by?.partyName}${addZero(
          party?.number
        )}${addZero(item?.index)}`,
        sender_name: "Кент Лии",
        receiver_name: getUserData(item, "fullName") || "-",
        passpor_address: getUserData(item, "passport_address"),
        short_name: getProductsName(item?.products),
        weight: getProductsTotalWeight(item?.products) / 1000 || 0,
        price: getProductsTotalPrice(item?.products) / 1000,
        currency: "USD / доллар США",
      });
    else {
      ws.addRow({
        index: "",
        party_name: "",
        sender_name: "",
        receiver_name: "",
        passpor_address: "TOTAL / Жами",
        short_name: "",
        weight: totalWeight / 1000,
        price: totalPrice / 1000,
        currency: "",
      });
    }
  });

  const header = ws.getRow(6);
  header.font = { bold: true, name: "Times New Roman", size: 9 };

  ws.getColumn("A").width = 5;
  ws.getColumn("B").width = 16;
  ws.getColumn("C").width = 16;
  ws.getColumn("D").width = 16;
  ws.getColumn("E").width = 20;
  ws.getColumn("F").width = 25;
  ws.getColumn("G").width = 16;
  ws.getColumn("H").width = 16;
  ws.getColumn("I").width = 16;

  // implement styles
  for (let i = 1; i <= header.cellCount; i++) {
    header.getCell(i).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "f6f6f6" },
    };

    header.getCell(i).alignment = {
      vertical: "middle",
      wrapText: true,
    };

    for (let r = 6; r <= ws.rowCount; r++) {
      ws.getCell(r, i).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      ws.getCell(r, i).alignment = {
        vertical: "middle",
        wrapText: true,
      };
    }
  }

  const lastRow = +ws.rowCount;
  ws.getCell(`E:${lastRow}`).font = headerDefFontStyle;
  ws.getCell(`G:${lastRow}`).font = headerDefFontStyle;
  ws.getCell(`H:${lastRow}`).font = headerDefFontStyle;
  // ws.mergeCells(`A:${lastRow + 1}:I:${lastRow + 1}`);
};

import { IProduct } from "@/common/types";
import services from "@/services";
import { useCells } from "@/store";
import { Button, Col, Form, Input, InputNumber, message, Row } from "antd";
import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";

const rules = [{ required: true }];

export const TrackForm = ({ inputRef }: any) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const isSeparately = useCells(({ isSeparately }) => isSeparately);

  const { mutate, isLoading } = useMutation(services.products.addProduct, {
    onSuccess: (res) => {
      const { data } = res;
      if (data?.isNewCellGroup) {
        message.warning(
          <span>
            This customer`s product{" "}
            <strong>{data?.product?.track_number}</strong> have been divided
            into another invoice. The reason is that the count of products
            exceeds <strong>30</strong> or the weight exceeds{" "}
            <strong>10 kg</strong>
          </span>,
          10
        );
      }

      if (res.status === 200) {
        message.success("Succesfully added");
        form.resetFields();
        inputRef?.current.focus();
        return;
      }

      if (res.status === 201) {
        message.warning(
          <span>
            This user&apos;s product{" "}
            <strong>{data?.product?.track_number}</strong> has in another Cell{" "}
            <strong>CELL {data?.cell?.cellNumber}</strong> and added to this
            cell
          </span>,
          10
        );
        form.resetFields();
      }
    },
    onError: (err: AxiosError) => {
      if (err?.response?.status === 409) {
        message.warn("The user's product has another cell, please choose cell");
      }
      if (err?.response && err?.response?.data?.message) {
        message.error(err?.response?.data?.message, 5);
        return;
      }
      message.error("Something went error");
    },
    onSettled: () => {
      queryClient.invalidateQueries("getCell");
    },
  });

  const { mutate: mutateSeparately, isLoading: isLoadingSeparately } =
    useMutation(services.products.addProductSeparately, {
      onSuccess: (res) => {
        const { data } = res;
        if (data?.isNewCellGroup) {
          message.warning(
            <span>
              This customer`s product{" "}
              <strong>{data?.product?.track_number}</strong> have been divided
              into another invoice. The reason is that the count of products
              exceeds <strong>30</strong> or the weight exceeds{" "}
              <strong>10 kg</strong>
            </span>,
            10
          );
        }

        if (res.status === 200) {
          message.success("Succesfully added");
          form.resetFields();
          inputRef?.current.focus();
          return;
        }

        if (res.status === 201) {
          message.warning(
            <span>
              This user&apos;s product{" "}
              <strong>{data?.product?.track_number}</strong> has in another Cell{" "}
              <strong>CELL {data?.cell?.cellNumber}</strong> and added to this
              cell
            </span>,
            10
          );
          form.resetFields();
        }
      },
      onError: (err: AxiosError) => {
        if (err?.response?.status === 409) {
          message.warn(
            "The user's product has another cell, please choose cell"
          );
        }
        if (err?.response && err?.response?.data?.message) {
          message.error(err?.response?.data?.message, 5);
          return;
        }
        message.error("Something went error");
      },
      onSettled: () => {
        queryClient.invalidateQueries("getCell");
      },
    });

  const cell = useCells(({ cell }) => cell);

  const handleAdd = async (values: IProduct) => {
    if (cell?.id) {
      isSeparately
        ? mutateSeparately({ ...values, cellId: cell.id })
        : mutate({ ...values, cellId: cell.id });
    }
  };

  return (
    <div className="container main-form">
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={handleAdd}
        wrapperCol={{ span: 23 }}
        requiredMark={false}
      >
        <Row align="top">
          <Col span={6}>
            <Form.Item label="Track Number" name="track_number" rules={rules}>
              <Input
                style={{ width: "100%" }}
                placeholder="Track Number"
                ref={inputRef}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item required label="User ID" name="user" rules={rules}>
              <Input style={{ width: "100%" }} placeholder="User ID" />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item required label="Specific ID" name="specificUser">
              <Input style={{ width: "100%" }} placeholder="Specific User ID" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item required label="Name" name="name" rules={rules}>
              <Input placeholder="Short name" />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item required label="Count" name="count" rules={rules}>
              <InputNumber
                style={{ width: "100%" }}
                controls={false}
                placeholder="Count"
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item required label="Weight" name="weight" rules={rules}>
              <InputNumber
                style={{ width: "100%" }}
                controls={false}
                placeholder="Weight"
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item style={{ textAlign: "right" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ minWidth: 150, marginTop: "22px" }}
                loading={isLoading || isLoadingSeparately}
              >
                Add
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

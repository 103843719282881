import { Button, PageHeader, Spin } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./MainContainer.module.scss";
import { ToPartyModal } from "./ToPartyModal";
import { useAuth } from "@/hooks/useAuth";
import { useParties } from "@/store";
import { addZero } from "@/common/utils/addZero";

const MainContainer = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const party = useParties(({ party }) => party);
  const loadingParty = useParties(({ isLoading }) => isLoading);

  const { logOut } = useAuth();
  return (
    <div className="container">
      <Spin spinning={loadingParty}>
        <PageHeader
          ghost={false}
          title={`Main. PARTY: ${party?.created_by?.partyName}${addZero(
            party?.number || 0
          )}`}
          extra={[
            <Button key="1" type="link" onClick={() => logOut()}>
              Log out
            </Button>,
          ]}
        />
        <div className={styles.main}>
          <Button className={styles.btn} type="primary">
            <Link to="/scan">Scanning</Link>
          </Button>

          <Button
            className={`${styles.btn} ${styles["btn-auto"]}`}
            type="default"
          >
            <Link to="/auto-scan">AUTO cargo</Link>
          </Button>

          <Button className={styles.btn} type="default">
            <Link to="/products">Products</Link>
          </Button>

          <Button
            onClick={() => setOpenModal(true)}
            className={styles.btn}
            type="dashed"
          >
            To Uzbekistan
          </Button>

          <Button className={styles.btn}>
            <Link to="/invoice/users">Invoices By User</Link>
          </Button>

          <Button danger type="dashed" className={styles.btn}>
            <Link to="/prohibited-goods">Prohibited goods</Link>
          </Button>
        </div>
        <ToPartyModal visible={openModal} setVisible={setOpenModal} />
      </Spin>
    </div>
  );
};

export default MainContainer;

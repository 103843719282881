import create from "zustand";
import { devtools } from "zustand/middleware";

type CellType = {
  isLoadingCell: boolean;
  cell: any;
  visibleModal: boolean;
  visibleGroupModal: boolean;
  isToCell: boolean;
  cellGroups: any;
  isSeparately: boolean;

  setCell: (value: any) => void;
  setVisibleModal: (value: boolean) => void;
  setVisibleGroupModal: (value: boolean) => void;
  setIsLoadingCell: (value: boolean) => void;
  setIsToCell: (value: boolean) => void;
  setCellGroups: (value: any) => void;
  setIsSeparately: (value: boolean) => void;
};

const useCells = create<CellType>(
  devtools(
    (set) => {
      return {
        isLoadingCell: false,
        cell: null,
        visibleModal: false,
        visibleGroupModal: false,
        isToCell: false,
        cellGroups: [],
        isSeparately: false,

        setCell: (value: any) => set({ cell: value }),
        setVisibleModal: (value: boolean) => set({ visibleModal: value }),
        setVisibleGroupModal: (value: boolean) =>
          set({ visibleGroupModal: value }),
        setIsLoadingCell: (value: boolean) => set({ isLoadingCell: value }),
        setIsToCell: (value: any) =>
          set({ isToCell: value, visibleModal: value }),
        setCellGroups: (value: any) => set({ cellGroups: value }),
        setIsSeparately: (value: boolean) => set({ isSeparately: value }),
      };
    },
    { name: "Cell" }
  )
);

export default useCells;

import axios from "axios";
import BaseApi from "../base.service";

class Auth extends BaseApi {
  constructor() {
    super();
  }

  async login({ phone, password }: any) {
    const hostname = window.location.hostname;
    const parts = hostname.split(".");
    let subdomain = "";

    // If the domain has more than two parts (e.g., subdomain.domain.com)
    if (parts.length > 2) {
      subdomain = parts[0];
    }

    const _api =
      subdomain?.indexOf("test") > -1
        ? process.env.REACT_APP_URL_TEST
        : process.env.REACT_APP_URL;

    const api = axios.create({
      baseURL: _api,
    });

    try {
      const response = await api.post("/auth/login", {
        phone,
        password,
      });

      return response;
    } catch (err) {
      return err;
    }
  }

  logout() {
    localStorage.removeItem("token");
  }
}

export default Auth;

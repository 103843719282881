import { Table } from "antd";
import { useParties, useProducts } from "@/store";
import { addZero } from "@/common/utils/addZero";
import dayjs from "dayjs";

export const ProductsTable = ({ isLoadingData }: any) => {
  const products = useProducts(({ products }) => products);
  const party = useParties(({ party }) => party);

  const state: any = {
    bordered: true,
    size: "small",
    title: undefined,
    scroll: undefined,
    hasData: true,
    tableLayout: undefined,
    top: "none",
    bottom: "bottomRight",
    pagination: false,
  };

  return (
    <div className="container">
      <div>
        <Table
          rowKey={(record) => {
            return record.id;
          }}
          {...state}
          dataSource={products}
          size="small"
          loading={isLoadingData}
        >
          <Table.Column
            title="Party"
            dataIndex="party"
            key="party"
            render={(text, record: any) => {
              return record?.party?.number
                ? `${party?.created_by?.partyName}${addZero(
                    record?.party?.number
                  )}`
                : `${party?.created_by?.partyName}${addZero(
                    party?.number || 0
                  )}`;
            }}
          />
          <Table.Column
            title="Cell"
            dataIndex="cell"
            key="cell"
            render={(text, record: any) => {
              return record?.cell ? record?.cell?.cellNumber : "⛔️";
            }}
          />
          <Table.Column
            title="Track Number"
            dataIndex="track_number"
            key="track_number"
          />
          <Table.Column
            title="User ID"
            dataIndex="user"
            key="user"
            width={120}
            render={(text, record: any) => {
              const _user = record?.specificUser?.id
                ? record?.specificUser
                : text;
              return _user.phone_extra;
            }}
          />
          <Table.Column
            title="Created at"
            dataIndex="created_at"
            key="created_at"
            width={160}
            render={(text, record: any) => {
              return dayjs(record?.created_at).format("DD.MM.YYYY HH:mm");
            }}
          />
          <Table.Column title="Name" dataIndex="name" key="name" width={320} />
          <Table.Column
            title="Count"
            dataIndex="count"
            key="count"
            width={60}
          />
          <Table.Column
            title="Weight"
            dataIndex="weight"
            key="weight"
            width={60}
          />
          <Table.Column
            title="Price"
            dataIndex="price"
            key="price"
            width={60}
          />
          <Table.Column
            title="Status"
            dataIndex="price"
            key="price"
            width={60}
            render={(text, record: any) => {
              return record?.box?.status;
            }}
          />
        </Table>
      </div>
    </div>
  );
};

import { useParties } from "@/store";
import { PageHeader } from "antd";
import { useNavigate } from "react-router-dom";

import "./styles.scss";

export const ScanHeader = () => {
  const navigate = useNavigate();
  const party = useParties(({ autoParty }) => autoParty);

  return (
    <div className="scan-header">
      <PageHeader
        ghost={false}
        title={`AUTO CARGO:  ${party?.name}`}
        onBack={() => navigate("/")}
      />
    </div>
  );
};

import { BoxStatus, IBox, IParty } from "@/common/types";
import BaseApi from "../base.service";

export default class CellApi extends BaseApi {
  constructor() {
    super();
  }
  getCell = async (partyId: number): Promise<any> => {
    const timestamp = Date.now();
    const result = await this.api.get(
      `/cell/open?partyId=${partyId}&timestamp=${timestamp}`
    );
    return result.data;
  };

  closeCell = async ({ id }: any) => {
    const result = await this.api.post(`/cell/${id}`);
    return result.data;
  };

  getAllCells = async (partyId: number | undefined) => {
    const result = await this.api.get(`/cell?partyId=${partyId}`);
    return result.data;
  };

  async getById(id: string) {
    const result = await this.api.get("/cell/" + id);
    return result.data;
  }

  productsToCell = async (ids: number[], id: number) => {
    const result = await this.api.patch(`/cell/to/${id}`, ids);
    return result.data;
  };

  getCellGroupsByParty = async (partyId: number) => {
    const result = await this.api.post(`/cell/cell-groups`, { partyId });
    return result.data;
  };

  getCellGroupsByUser = async (
    partyId: number | undefined,
    userId: number | undefined
  ) => {
    const result = await this.api.get(
      `/cell/group?partyId=${partyId}&userId=${userId}`
    );
    return result.data;
  };
}

import services from "@/services";
import { useParties } from "@/store";
import { Descriptions, message, Modal, Spin } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";

interface ToPartyModalProps {
  visible: boolean;
  setVisible: (v: boolean) => void;
}

export const ToPartyAutoModalModal = ({
  visible,
  setVisible,
}: ToPartyModalProps) => {
  const party = useParties(({ autoParty }) => autoParty);
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(
    ["partyInfo", party],
    () => services.products.getCurrentParyInfo(party?.id || 0),
    {
      enabled: visible && !!party,
    }
  );

  const { mutate, isLoading: isLoadingParty } = useMutation(
    (id: number) => services.products.sendAutoPartyToUzebekistan(id),
    {
      onSuccess: () => {
        message.success("Succesfully sended to uzbekistan");
        setVisible(false);
      },
      onError: () => {
        message.error("Something went error");
      },
      onSettled: () => {
        queryClient.invalidateQueries("getAutoChina");
      },
    }
  );

  const handleChangeStatus = () => {
    if (data?.id) {
      mutate(data?.id);
    } else {
      message.warn("Please refresh the page");
    }
  };

  return (
    <Modal
      title="AUTO CARGO"
      visible={visible}
      onCancel={() => setVisible(false)}
      onOk={handleChangeStatus}
      okButtonProps={{
        loading: isLoadingParty,
        disabled: !data?.id,
      }}
      okText="Send to Uzbekistan"
    >
      <Spin tip="...Loading" spinning={isLoading || isLoadingParty}>
        <div style={{ minHeight: "100px" }}>
          <Descriptions title={`Party: ${party?.name}`}>
            <Descriptions.Item>
              <br />
              Total users: {data?.totalUsers}
              <br />
              Total products: {data?.totalProducts}
              <br />
              Total price: {Math.floor((data?.totalPrice || 0) * 1000) / 1000}
              <br />
              Total weight: {Math.floor((data?.totalWeight || 0) * 1000) / 1000}
            </Descriptions.Item>
          </Descriptions>
        </div>
      </Spin>
    </Modal>
  );
};

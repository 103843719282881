import { BoxStatus, IBox, IProduct } from "@/common/types";
import {
  getProductsTotalCount,
  getProductsTotalPrice,
  getProductsTotalWeight,
} from "@/common/utils/getUser";
import services from "@/services";
import { useCells, useParties, useProducts } from "@/store";
import {
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { Badge, Button, message, Modal, Space, Spin, Table, Tag } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";

export const CellGroupModal = () => {
  const queryClient = useQueryClient();
  const party = useParties(({ party }) => party);
  const cell = useCells(({ cell }) => cell);
  const visible = useCells(({ visibleGroupModal }) => visibleGroupModal);
  const setVisible = useCells(
    ({ setVisibleGroupModal }) => setVisibleGroupModal
  );
  const selectedRowKeys = useProducts(({ selectedRowKeys }) => selectedRowKeys);
  const setSelectedRowKeys = useProducts(
    ({ setSelectedRowKeys }) => setSelectedRowKeys
  );

  const [expandedRowKeys, setExpandedRowKeys] = useState<any>([]);
  const [cellState, setCellState] = useState([]);

  const { data: cells, isLoading } = useQuery(
    ["getCellGroupByUserQuery", party],
    () =>
      services.cell.getCellGroupsByUser(
        party?.id,
        selectedRowKeys[0]?.user?.id
      ),
    {
      enabled: visible && !!party?.id && selectedRowKeys?.length > 0,
    }
  );

  const { mutate: updateProducts, isLoading: isUpdating } = useMutation(
    (data: any) => services.products.updateProductsCellGroup(data),
    {
      onSuccess: () => {
        message.success("Succesfully added to selected group");
        setSelectedRowKeys([]);
        setVisible(false);
      },
      onError: (err) => {
        message.error("Something went error");
      },
      onSettled: () => {
        queryClient.invalidateQueries("getCell");
      },
    }
  );

  const { mutate: addProducts, isLoading: isAdding } = useMutation(
    () =>
      services.products.addProductsToCellGroup({
        partyId: party?.id,
        cellId: cell.id,
        userId: selectedRowKeys[0] && selectedRowKeys[0]?.user?.id,
        products: selectedRowKeys,
      }),
    {
      onSuccess: () => {
        message.success("Succesfully divided to new invoice");
        setSelectedRowKeys([]);
        setVisible(false);
      },
      onError: (err) => {
        message.error("Something went error");
      },
      onSettled: () => {
        queryClient.invalidateQueries("getCell");
      },
    }
  );

  useEffect(() => {
    if (cells && cells.length > 0) {
      const _cells = cells.map((item: any) => {
        let totalWeight = 0;
        let totalCount = 0;
        item.products?.forEach((product: IProduct) => {
          totalWeight += product.weight ? product.weight * 1000 : 0;
          totalCount += product.count ? product.count : 0;
        });
        return {
          ...item,
          weight: totalWeight / 1000 || 0,
          count: totalCount,
        };
      });

      setCellState(_cells);
    }
  }, [cells]);
  useEffect(() => {
    if (!visible) setExpandedRowKeys([]);
  }, [visible]);

  const handleToCellGroup = (cellGroupId: number | undefined) => {
    cellGroupId
      ? updateProducts({
          cellGroupId,
          productIds: selectedRowKeys?.map((item: any) => item.id),
        })
      : addProducts();
  };

  const expandedRowRender = (record: any) => {
    const columns = [
      {
        title: "№",
        dataIndex: "_index",
        key: "id",
        render: (text: any, record: any, index: any) => index + 1,
      },
      { title: "Track", dataIndex: "track_number", key: "track" },
      {
        title: "Cell",
        dataIndex: "cell",
        key: "cell.id",
        render: (text: any) => `Cell${record?.cell?.cellNumber}`,
      },
      { title: "Name rus", dataIndex: "name_ru", key: "name_ru" },
      { title: "Name", dataIndex: "name", key: "name" },
      { title: "Weight", dataIndex: "weight", key: "weight" },
      { title: "Count", dataIndex: "count", key: "count" },
      { title: "Price", dataIndex: "price", key: "price" },
      {
        title: "Created date",
        dataIndex: "created_at",
        key: "created_at",
        render: (text: any) => dayjs(text).format("DD MMM YYYY"),
      },
    ];

    const data = record?.products;

    return (
      <Table
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    );
  };

  const TableFooter = () => (
    <Space align="center" style={{ width: "100%", justifyContent: "flex-end" }}>
      <Button
        type="primary"
        style={{ minWidth: "200px" }}
        onClick={() => handleToCellGroup(undefined)}
        loading={isAdding}
        // disabled={products?.length === 0}
      >
        + Add to new one
      </Button>
    </Space>
  );

  const state: any = {
    bordered: true,
    loading: false,
    size: "small",
    title: undefined,
    scroll: undefined,
    hasData: true,
    tableLayout: undefined,
    top: "none",
    bottom: "bottomRight",
    pagination: false,
    footer: TableFooter,
  };

  return (
    <Modal
      title="Cell Groups"
      footer={false}
      visible={visible}
      onCancel={() => setVisible(false)}
      width={900}
    >
      <Spin tip="...Loading" spinning={isLoading || isAdding || isUpdating}>
        <div style={{ minHeight: "100px" }}>
          <Spin spinning={isLoading}>
            <Table
              rowKey={"id"}
              dataSource={cellState || []}
              size="small"
              loading={isLoading}
              expandable={{
                expandedRowRender: expandedRowRender,
                columnWidth: 40,
                showExpandColumn: true,
                onExpandedRowsChange: (rows: any) => {
                  if (rows.length > 1) {
                    setExpandedRowKeys([rows[rows?.length - 1]]);
                    return;
                  } else {
                    setExpandedRowKeys(rows);
                  }
                },
                expandedRowKeys: expandedRowKeys,
              }}
              {...state}
            >
              <Table.Column
                title="UserID"
                dataIndex="user"
                key="user"
                width={120}
                render={(text) => <strong>{text?.phone_extra}</strong>}
              />
              <Table.Column
                title="Cell"
                dataIndex="cell"
                key="products_cell"
                width={120}
                render={(text, _, index: number) =>
                  `${text?.cellNumber}.${index + 1}`
                }
              />
              <Table.Column
                title="Products count"
                dataIndex="products"
                key="products_count"
                width={120}
                render={(text) => getProductsTotalCount(text)}
              />
              <Table.Column
                title="Total weight"
                dataIndex="products"
                key="products_weight"
                width={120}
                render={(text) => `${getProductsTotalWeight(text) / 1000} kg`}
              />
              <Table.Column
                title="Total price"
                dataIndex="products"
                key="products_price"
                width={120}
                render={(text) => `${getProductsTotalPrice(text) / 1000} $`}
              />
              <Table.Column
                title="Action"
                key="action"
                render={(record) => (
                  <Space size="middle">
                    <Button
                      type="primary"
                      key="add"
                      icon={<PlusOutlined />}
                      size="small"
                      onClick={() => handleToCellGroup(record.id)}
                    >
                      Add to this
                    </Button>
                  </Space>
                )}
              />
            </Table>
          </Spin>
        </div>
      </Spin>
    </Modal>
  );
};

import { IBox, IParty } from "@/common/types";
import create from "zustand";
import { devtools } from "zustand/middleware";

type BoxState = {
  isLoadingBox: boolean;
  box: IBox | null;
  visibleModal: boolean;
  isToBox: boolean;
  parties: IParty[];

  setBox: (values: IBox) => void;
  setVisibleModal: (value: boolean) => void;
  setIsToBox: (value: boolean) => void;
  setIsLoadingBox: (value: boolean) => void;

  setParties: (value: IParty[]) => void;
};

const useBoxes = create<BoxState>(
  devtools(
    (set) => {
      return {
        isLoadingBox: false,
        box: null,
        visibleModal: false,
        isToBox: false,
        parties: [],

        setBox: (value) => set({ box: value }),
        setVisibleModal: (value) => set({ visibleModal: value }),
        setIsLoadingBox: (value) => set({ isLoadingBox: value }),
        setIsToBox: (value) => set({ isToBox: value, visibleModal: value }),
        setParties: (value) => set({ parties: value }),
      };
    },
    { name: "BOX" }
  )
);

export default useBoxes;

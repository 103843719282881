import { ADD_KEY, SEARCH_KEY } from "@/common/types";
import services from "@/services";
import { useParties, useProducts } from "@/store";
import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { ScanHeader } from "./components/ScanHeader";
import { MainActions } from "./components/ScanMainActions";
import { TrackForm } from "./components/TrackForm";
import { TrackTable } from "./components/TrackTable";

const AutoScanningWrapper = () => {
  const setProducts = useProducts(({ setAutoProducts }) => setAutoProducts);
  const setAllProducts = useProducts(
    ({ setAllAutoProducts }) => setAllAutoProducts
  );
  const setPartyLoading = useParties(({ setPartyLoading }) => setPartyLoading);
  const setParty = useParties(({ setAutoParty }) => setAutoParty);

  const searchRef = useRef<HTMLInputElement>();
  const inputRef = useRef<null | HTMLInputElement>(null);

  const { data, isSuccess, isLoading } = useQuery(
    ["getAutoChina"],
    () => {
      return services.products.getAutoParty();
    },
    {
      refetchOnMount: false,
    }
  );

  useEffect(() => {
    document.addEventListener("keydown", handleOnKeyDown);

    return () => {
      document.removeEventListener("keydown", handleOnKeyDown);
    };
  }, []);

  const handleOnKeyDown = (e: KeyboardEvent) => {
    if (e.key === SEARCH_KEY.KEY && e.keyCode === SEARCH_KEY.CODE) {
      e.preventDefault();
      searchRef?.current && searchRef.current.focus();
    }
    if (e.key === ADD_KEY.KEY && e.keyCode === ADD_KEY.CODE) {
      e.preventDefault();
      inputRef?.current && inputRef.current.focus();
    }
  };

  useEffect(() => {
    if (isSuccess && data) {
      setParty(data);
      setProducts(data?.products);
      setAllProducts(data?.products);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    setPartyLoading(isLoading);
  }, [isLoading]);

  return (
    <div>
      <ScanHeader />
      <MainActions searchRef={searchRef} />
      <TrackForm inputRef={inputRef} />
      <TrackTable />
    </div>
  );
};

export default AutoScanningWrapper;
